import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {AutoComplete} from "primereact/autocomplete";
import {BlockUI} from "primereact/blockui";
import Grid from "@mui/material/Grid";
import {Button} from "primereact/button";
import {Box} from "@mui/system";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultDataValues={
	customersList: [],
};

let defaultInputError={
	errorText: "",
};

const ChooseCustomer = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [selectedCustomer, setSelectedCustomer] = useState((props.customer.id?props.customer:null));
	const [filteredCustomersList, setFilteredCustomersList] = useState([]);
	const [inputError, setInputError] = useState(defaultInputError);	
    const location = useLocation();
    const navigate = useNavigate();

	const handleButton = (evt,what) => {
		setInputError(defaultInputError);
		if(what==="select") {
			if(selectedCustomer) {
				props.selectCustomerHandler(selectedCustomer);
			}else{
				setInputError({errorText: "Please select a customer from the list!"});				
			}
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="get") {
			setDataValues({...dataValues,customersList:(result.customersList?result.customersList:[])});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getCustomers";
			url+="?session_id="+props.session.sessId;
			let data={
				short: 1,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

    const searchCustomers = (evt) => {
        setTimeout(() => {
            let _filteredCustomersList;
            if (!evt.query.trim().length) {
                _filteredCustomersList = [...dataValues.customersList];
            }else{
                _filteredCustomersList = dataValues.customersList.filter((customer) => {
                    return customer.name.toLowerCase().startsWith(evt.query.toLowerCase());
                });
            }
            setFilteredCustomersList(_filteredCustomersList);
        }, 250);
    }

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={10} className="field padding-med">
							<AutoComplete value={selectedCustomer} suggestions={filteredCustomersList} completeMethod={searchCustomers} field="name" style={{"width":"100%"}} dropdown forceSelection showEmptyMessage minLength={3} onChange={(evt) => setSelectedCustomer(evt.value)} onClick={(evt) => setInputError(defaultInputError)}/>
						</Grid>
						<Grid item xs={2} className="field padding-med">
							<Button icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"select")}/>
						</Grid>
						<Grid item xs={12} style={{"textAlign":"left","marginTop":"12px"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"0px"}}>
								{inputError.errorText}
							</div>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={10} className="field padding-med">
							<AutoComplete value={selectedCustomer} suggestions={filteredCustomersList} completeMethod={searchCustomers} field="name" style={{"width":"100%"}} dropdown forceSelection showEmptyMessage minLength={3} onChange={(evt) => setSelectedCustomer(evt.value)} onClick={(evt) => setInputError(defaultInputError)}/>
						</Grid>
						<Grid item xs={2} className="field padding-med">
							<Button label="Select" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"select")}/>
						</Grid>
						<Grid item xs={12} style={{"textAlign":"left","marginTop":"12px"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"0px"}}>
								{inputError.errorText}
							</div>
						</Grid>
					</Grid>
				</Box>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}
};

export default ChooseCustomer;
