import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import Grid from "@mui/material/Grid";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {confirmDialog} from "primereact/confirmdialog";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultDataValues={
	operationsList: [],
	inventoryList: [],
};

let defaultInputValues={
	inventory_id: null,
	quantity: "",
	filter_description: "",
	filter_sku: "",
	cost_price: 0,
	currency: "DHS",
	xe_rate: 3.67,
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
};

let currencyList=[
	{
		label: "DHS",value: "DHS",
	},
	{
		label: "EUR",value: "EUR",
	},	
	{
		label: "USD",value: "USD",
	},
];

const InventoryOperations = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(false);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [operationsList, setOperationsList] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="filter_description") {
			setOperationsList(dataValues.operationsList
													.filter((item)=>((item.description.toLowerCase().indexOf(value.toLowerCase())>-1))||(value===""))
													.filter((item)=>((item.sku.toLowerCase().indexOf(inputValues.filter_sku.toLowerCase())>-1))||(inputValues.filter_sku===""))
			);
		}
		if(name==="filter_sku") {
			setOperationsList(dataValues.operationsList
													.filter((item)=>((item.description.toLowerCase().indexOf(inputValues.filter_description.toLowerCase())>-1))||(inputValues.filter_description===""))
													.filter((item)=>((item.sku.toLowerCase().indexOf(value.toLowerCase())>-1))||(value===""))
			);
		}
		if((name==="quantity")||(name==="xe_rate")) {
			if(!evt.target.validity.valid) {
				setMessageText("Error: Only numbers are allowed!");							
				return false;
			}
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Add new operation"});
		}
		if(what==="edit") {
			elementData.filter_description=inputValues.filter_description;
			elementData.filter_sku=inputValues.filter_sku;
			console.log(elementData);
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Edit operation"});
		}
		if(what==="delete") {
			let url="deleteInventoryOperation";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
				expense_file_id: props.expenseId,
				customer_id: props.customerId,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(!inputValues.inventory_id) {
				setMessageText("Error: Please select item!");				
				document.getElementById("inventory_id").classList.add("required_input_error");
				return false;
			}
			if((!inputValues.quantity)||(inputValues.quantity==="0")) {
				setMessageText("Error: Please specify quantity!");				
				document.getElementById("quantity").classList.add("required_input_error");
				return false;
			}
			if(inputValues.price==="") {
				setMessageText("Error: Please specify unit price!");				
				document.getElementById("cost_price").classList.add("required_input_error");
				return false;
			}
			if((!inputValues.xe_rate)||(inputValues.xe_rate==0)) {
				setMessageText("Error: Please specify exchange rate!");				
				document.getElementById("xe_rate").classList.add("required_input_error");
				return false;
			}
			if((!inputValues.cost_price)||(inputValues.cost_price==0)) {
				setMessageText("Error: Please specify unit price!");				
				document.getElementById("cost_price").classList.add("required_input_error");
				return false;
			}
			let url="saveInventoryOperation";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data={...inputValues};
			data.expense_file_id=props.expenseId;
			data.customer_id=props.customerId;
			data.xe_rate=(inputValues.currency==="DHS"?1:inputValues.xe_rate);
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}	
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save")||(oper==="delete")) {
			setDialogValues(defaultDialogValues);
			if(oper==="get") {
				setDataValues({...dataValues,operationsList:(result.operationsList?result.operationsList:[]),
												inventoryList:(result.inventoryList?result.inventoryList:[])
				});
			}else{
				setDataValues({...dataValues,operationsList:(result.operationsList?result.operationsList:[])});				
			}
			console.log(inputValues);
			setOperationsList(result.operationsList?result.operationsList
																		.filter((operation)=>((operation.description.toLowerCase().indexOf(inputValues.filter_description.toLowerCase())>-1))||(inputValues.filter_description===""))
																		.filter((operation)=>((operation.sku.toLowerCase().indexOf(inputValues.filter_sku.toLowerCase())>-1))||(inputValues.filter_sku===""))
																		:[]);
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getInventoryOperations";
			url+="?session_id="+props.session.sessId;
			let data={
				expense_file_id: props.expenseId,
				customer_id: props.customerId,
			};
			fetch_url_post(url,data,success_handler,fail_handler);		
		}else{
			navigate("/login");
		}
	},[]);

	const addEditDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px","maxHeight":"72vh"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="inventory_id">Product*</label>
						<Dropdown name="inventory_id" id="inventory_id" style={{"width":"100%"}} options={dataValues.inventoryList} filter value={inputValues.inventory_id} optionLabel="sku_description" optionValue="id" onChange={(evt) => handleChange(evt)}/>
					</Grid>
					{!!(inputValues.currency==="DHS") &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={4} lg={4} className="field padding-med">
								<label htmlFor="quantity">Quantity*</label>
								<InputText name="quantity" id="quantity" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValues.quantity} onChange={(evt) => handleChange(evt)}/>							
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} className="field padding-med">
								<label htmlFor="unit_price">Cost price/unit*</label>
								<div className="p-inputgroup">
									<InputText name="cost_price" id="cost_price" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValues.cost_price} onChange={(evt) => handleChange(evt)}/>
									<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{inputValues.currency}</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} className="field padding-med">
								<label htmlFor="currency">Currency*</label>
								<Dropdown name="currency" id="currency" style={{"width":"100%","height":"40px"}} options={currencyList} value={inputValues.currency} onChange={(evt) => handleChange(evt)}/>
							</Grid>						
						</React.Fragment>
					}
					{!!(inputValues.currency!=="DHS") &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
								<label htmlFor="quantity">Quantity*</label>
								<InputText name="quantity" id="quantity" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValues.quantity} onChange={(evt) => handleChange(evt)}/>							
							</Grid>
							<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
								<label htmlFor="unit_price">Cost price/unit*</label>
								<div className="p-inputgroup">
									<InputText name="cost_price" id="cost_price" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValues.cost_price} onChange={(evt) => handleChange(evt)}/>
									<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{inputValues.currency}</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
								<label htmlFor="currency">Currency*</label>
								<Dropdown name="currency" id="currency" style={{"width":"100%","height":"40px"}} options={currencyList} value={inputValues.currency} onChange={(evt) => handleChange(evt)}/>
							</Grid>						
							<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
								<label htmlFor="quantity">Exchange rate*</label>
								<div className="p-inputgroup">
									<InputText name="xe_rate" id="xe_rate" style={{"width":"100%"}} pattern={"[0-9.]*"} inputmode="numeric" value={inputValues.xe_rate} onChange={(evt) => handleChange(evt)}/>
									<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>DHS</span>
								</div>
							</Grid>
						</React.Fragment>
					}
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const tableColTemplate = (row_data,column) => {
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="Edit" icon="pi pi-pencil" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
					<Button label="Delete" icon="pi pi-trash" className="p-button-sm p-button-raised p-button-danger" style={{}} onClick={(evt) => confirm(evt,"delete",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateSmall = (row_data,column) => {
		if(column.field==="buttons") {
			return(
				<span>
					<Button tooltip="Edit" icon="pi pi-pencil" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
					<Button tooltip="Delete" icon="pi pi-trash" className="p-button-sm p-button-raised p-button-danger" style={{}} onClick={(evt) => confirm(evt,"delete",row_data)}/>
				</span>
			);
		}
	}

	const confirm = (evt,what,elementData) => {
		if((what==="delete")||(what==="delete_unit")||(what==="delete_group")) {
			confirmDialog({
				message: "Are you sure you want to delete?",
				header: "Confirm delete",
				icon: "pi pi-exclamation-triangle",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<div>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="field padding-med">
							<InputText name="filter_description" id="filter_description" style={{"width":"100%"}} value={inputValues.filter_description} placeholder="Filter list by name..." onChange={(evt) => handleChange(evt)}/>							
						</Grid>						
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="field padding-med">
							<InputText name="filter_sku" id="filter_sku" style={{"width":"100%"}} value={inputValues.filter_sku} placeholder="Filter list by SKU..." onChange={(evt) => handleChange(evt)}/>							
						</Grid>						
						<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="field padding-med text-right">
							<Button label="Add operation" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" style={{}} onClick={(evt) => {handleButton(evt,"add")}}/>
						</Grid>						
					</Grid>
					<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
						<DataTable
							value={operationsList}
							scrollable
							scrollHeight="60vh"
							style={{"minHeight":"63vh"}}
							scrollDirection="both"
							stripedRows
							resizableColumns
							columnResizeMode="fit"
							responsiveLayout="stack"
						>
							<Column field="description" header="Item name" style={{"flexGrow":"1","flexBasis":"560px"}} sortable></Column>
							<Column field="sku" header="Item SKU" style={{"flexGrow":"1","flexBasis":"110px"}} sortable></Column>
							<Column field="id" header="Debug" style={{"flexGrow":"0","flexBasis":"90px"}}></Column>
							<Column field="quantity" header="Quantity" style={{"flexGrow":"0","flexBasis":"90px"}}></Column>							
							<Column field="registered_by" header="Operated by" style={{"flexGrow":"1","flexBasis":"120px"}} sortable></Column>
							<Column field="reg_date_parsed" header="Date" style={{"flexGrow":"0","flexBasis":"140px"}}></Column>
							<Column field="buttons" header="Operations" style={{"flexGrow":"0","flexBasis":"160px"}} body={tableColTemplate}></Column>
						</DataTable>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
						<DataTable
							value={operationsList}
							responsiveLayout="stack"
						>
							<Column field="description" header="Item name"></Column>
							<Column field="sku" header="Item SKU"></Column>
							<Column field="quantity" header="Quantity"></Column>							
							<Column field="registered_by" header="Registered by"></Column>
							<Column field="reg_date_parsed" header="Date"></Column>
							<Column field="buttons" header="Operations" body={tableColTemplateSmall}></Column>
						</DataTable>
					</Box>
				</div>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
            </BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default InventoryOperations;
