import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import Grid from "@mui/material/Grid";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {ScrollPanel} from "primereact/scrollpanel";
import {Card} from "primereact/card";
import {Tag} from "primereact/tag";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultDataValues={
	activitiesList: [],
	usersList:[],
};

let defaultInputValues={
	filter_user: 0,
	filter_message: "",
	filter_severity: -1,
	period: "31",
};

let severitiesList=[
	{label: "All severities",value: -1},
	{label: "Info",value: 0},
	{label: "Warning",value: 1},
	{label: "Attention",value: 2},	
];

let periodsList=[
	{label: "All",value: ""},
	{label: "Today",value: "1"},
	{label: "Last week",value: "7"},
	{label: "Last month",value: "31"},	
];

let usersListFilter=[
	{user_name: "All users",id: 0},
];

const ActivityLog = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(false);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [activitiesList, setActivitiesList] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="filter_message") {
			setActivitiesList(dataValues.activitiesList
													.filter((activity)=>((activity.message.toLowerCase().indexOf(value.toLowerCase())>-1))||(value===""))
													.filter((activity)=>((activity.user_id===inputValues.filter_user))||(inputValues.filter_user===0))
													.filter((activity)=>((activity.severity===inputValues.filter_severity))||(inputValues.filter_severity===-1))
			);
		}
		if(name==="filter_user") {
			setActivitiesList(dataValues.activitiesList
													.filter((activity)=>((activity.message.toLowerCase().indexOf(inputValues.filter_message.toLowerCase())>-1))||(inputValues.filter_message===""))
													.filter((activity)=>((activity.user_id===value))||(value===0))
													.filter((activity)=>((activity.severity===inputValues.filter_severity))||(inputValues.filter_severity===-1))
			);
		}
		if(name==="filter_severity") {
			setActivitiesList(dataValues.activitiesList
													.filter((activity)=>((activity.message.toLowerCase().indexOf(inputValues.filter_message.toLowerCase())>-1))||(inputValues.filter_message===""))
													.filter((activity)=>((activity.user_id===inputValues.filter_user))||(inputValues.filter_user===0))
													.filter((activity)=>((activity.severity===value))||(value===-1))
			);
		}
		setInputValues({...inputValues,[name]:value});
		if(name==="period") {
			oper="get";
			let url="getActivityList";
			url+="?session_id="+props.session.sessId;
			let data={
				user_id: (props.user?props.user.id:null),
				period: value,
			};
			setDataOk(false);
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="get") {
			setDataValues({...dataValues,activitiesList:(result.activitiesList?result.activitiesList:[]),
										usersList:(result.usersList?result.usersList:[])
			});
			setActivitiesList(result.activitiesList?result.activitiesList:[]);
			usersListFilter=[
				{user_name: "All users",id: 0},
			];
			for(var i=0;i<result.usersList.length;i++) {
				usersListFilter.push(result.usersList[i]);
			}
            setDataOk(true);
//			setInputValues({...inputValues,filter_message: "",filter_user: 0,filter_severity: -1});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getActivityList";
			url+="?session_id="+props.session.sessId;
			let data={
				user_id: (props.user?props.user.id:null),
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

    const cardHeader = (activity) => {
		return(
			<div style={{"paddingTop":"12px"}}>
				<div style={{"width":"21%","float":"left","textAlign":"left","paddingLeft":"18px"}}>
					<h6 className="m-0" style={{"width":"100%","float":"left"}}>{activity.date_parsed}</h6>
				</div>
				<div style={{"width":"30%","float":"left","textAlign":"left"}}>
					<h6 className="m-0" style={{"width":"100%","float":"left"}}>{activity.user_name}</h6>
				</div>
				<div style={{"width":"21%","float":"left","textAlign":"left"}}>
					<h6 className="m-0" style={{"width":"100%","float":"left"}}>{activity.ip}</h6>
				</div>
				<div style={{"width":"21%","float":"left","textAlign":"left","marginTop":"-3px"}}>
					{activity.severity===0 &&
						<Tag severity="info" value="INFO"></Tag>
					}
					{activity.severity===1 &&
						<Tag severity="warning" value="WARNING"></Tag>
					}
					{activity.severity===2 &&
						<Tag severity="danger" value="ATTENTION"></Tag>
					}
				</div>
			</div>
		)
    };

    const cardHeaderMin = (activity) => {
		return(
			<div style={{"paddingTop":"12px"}}>
				<div style={{"width":"96%","margin":"0 auto","textAlign":"left","paddingLeft":"18px"}}>
					<h6 className="m-0" style={{"width":"100%","float":"left"}}>{activity.date_parsed}</h6>
				</div>
				<div style={{"width":"96%","margin":"0 auto","textAlign":"left","paddingLeft":"18px"}}>
					<h6 className="m-0" style={{"width":"100%","float":"left"}}>{activity.user_name}</h6>
				</div>
				<div style={{"width":"65%","float":"left","textAlign":"left","paddingLeft":"18px"}}>
					<h6 className="m-0" style={{"width":"100%","float":"left","paddingLeft":"6px"}}>{activity.ip}</h6>
				</div>
				<div style={{"width":"33%","float":"left","textAlign":"left","paddingLeft":"12px","marginTop":"-39px"}}>
					{activity.severity===0 &&
						<Tag severity="info" value="INFO"></Tag>
					}
					{activity.severity===1 &&
						<Tag severity="warning" value="WARNING"></Tag>
					}
					{activity.severity===2 &&
						<Tag severity="danger" value="ATTENTION"></Tag>
					}
				</div>
			</div>
		)
    };

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
					<div>
						<div style={{"width":"96%","margin":"0 auto","paddingLeft":"6px"}}>
							<InputText name="filter_message" id="filter_message" style={{"width":"100%"}} value={inputValues.filter_message} placeholder="Filter activity list..." onChange={(evt) => handleChange(evt)}/>							
						</div>
						{!!(!props.user) &&
							<div style={{"width":"96%","margin":"0 auto","paddingLeft":"6px","paddingTop":"6px"}}>
								<Dropdown name="filter_user" id="filter_user" style={{"width":"100%","height":"40px"}} options={usersListFilter} value={inputValues.filter_user} optionLabel="user_name" optionValue="id" onChange={(evt) => handleChange(evt)}/>				
							</div>
						}
						<div style={{"width":"96%","margin":"0 auto","paddingLeft":"6px","paddingTop":"6px"}}>
							<Dropdown name="filter_severity" id="filter_severity" style={{"width":"100%","height":"40px"}} options={severitiesList} value={inputValues.filter_severity} onChange={(evt) => handleChange(evt)}/>				
						</div>
						<div style={{"width":"96%","margin":"0 auto","paddingLeft":"6px","paddingTop":"6px","paddingBottom":"12px"}}>
							<Dropdown name="period" id="period" style={{"width":"100%","height":"40px"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>				
						</div>
						<Grid container spacing={1} style={{"paddingTop":"3px","paddingLeft":"12px"}}>
								{activitiesList.length>0 && activitiesList.map((activity) =>
									<Grid item xs={12} className="field padding-med">
										<Card header={cardHeaderMin(activity)} key={activity.id} style={{}}>				
											<Grid container spacing={1} style={{"paddingLeft":"12px"}}>
												<Grid item xs={12} className="padding-min">
													<p style={{"padding":"0px","marginBottom":"0px"}}>{activity.message}</p>
												</Grid>																																																									
											</Grid>
										</Card>
									</Grid>
								)}
								{activitiesList.length===0 &&
									<Grid item xs={12} className="field padding-med">
										<p style={{"color":"#FF0000"}}>There are no activity for selected criteria.</p>
									</Grid>
								}
						</Grid>
					</div>
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
					<div>
						<div style={{"width":"31%","float":"left","paddingLeft":"12px"}}>
							<InputText name="filter_message" id="filter_message" style={{"width":"100%"}} value={inputValues.filter_message} placeholder="Filter activity list..." onChange={(evt) => handleChange(evt)}/>							
						</div>
						{!!(!props.user) &&
							<div style={{"width":"24%","float":"left","paddingLeft":"12px"}}>
								<Dropdown name="filter_user" id="filter_user" style={{"width":"100%","height":"40px"}} options={usersListFilter} value={inputValues.filter_user} optionLabel="user_name" optionValue="id" onChange={(evt) => handleChange(evt)}/>				
							</div>
						}
						<div style={{"width":"21%","float":"left","paddingLeft":"12px"}}>
							<Dropdown name="filter_severity" id="filter_severity" style={{"width":"100%","height":"40px"}} options={severitiesList} value={inputValues.filter_severity} onChange={(evt) => handleChange(evt)}/>				
						</div>
						<div style={{"width":"21%","float":"left","paddingLeft":"12px"}}>
							<Dropdown name="period" id="period" style={{"width":"100%","height":"40px"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>				
						</div>
						<ScrollPanel style={{"width":"100%","height":"90%"}}>
							<Grid container spacing={1} style={{"paddingTop":"12px","paddingLeft":"12px","maxHeight":"69vh"}}>
								{activitiesList.length>0 && activitiesList.map((activity) =>
									<Grid item xs={12} className="field padding-med">
										<Card header={cardHeader(activity)} key={activity.id} style={{}}>				
											<Grid container spacing={1} style={{"paddingLeft":"12px"}}>
												<Grid item xs={12} className="padding-min">
													<p style={{"padding":"0px","marginBottom":"0px"}}>{activity.message}</p>
												</Grid>																																																									
											</Grid>
										</Card>
									</Grid>
								)}
								{activitiesList.length===0 &&
									<Grid item xs={12} className="field padding-med">
										<p style={{"color":"#FF0000"}}>There are no activity for selected criteria.</p>
									</Grid>
								}
							</Grid>
						</ScrollPanel>
					</div>
				</Box>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default ActivityLog;
