import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";
import {confirmDialog} from "primereact/confirmdialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Tag} from "primereact/tag";
import {BlockUI} from "primereact/blockui";
import {ScrollPanel} from "primereact/scrollpanel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultInputValues={
	id: null,
	name: "",
	address: "",
	emirate: "",
	country: "",
	po_box: "",
	bank: "",
	bank_account: "",
	credit_period: 0,
	vat_registered: 0,
	vat_on_invoice: 0,
	trn: "",
	contact_person: "",
	contact_phone: "",
	contact_email: "",
	monitoring_email_invoices: "",
	monitoring_email_expenses: "",
	monitoring_sms_invoices: "",
	monitoring_sms_expenses: "",	
	active: 1,
	observations: "",
	users_list: [],
	filter_customers: "",
	filter_customer_users: "",
	reg_date_parsed: null,
	invoice_numbering: "AUTOMATIC",
	invoice_numbering_start: 1, 
	inventory_method: "WA",
	walk_in: 1,
	partial_payments: 1,
};

let defaultInputValuesCustomerUser={
	id: "",
	name: "",
	user_name: "",
	password: "",
	confirm_password: "",
	lock_prices: 0,
	mobile_printer: "none",
	inventory: 0,
	observations: "",
	active: 1,
	list_invoices: 0,
	delete_invoices: 0,
	list_clients: 0,
	delete_clients: 0,
	max_discount: 0,
	payments: 0,	
};

let defaultDataValues={
	customersList: [],
	customerUsersList: [],
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
	openCustomerUsers: false,
	titleCustomerUsers: "Registered users for customer",
	openAddEditCustomerUser: false,
	titleAddEditCustomerUser: "",
	openChangeCustomerUserPassword: false,
	titleChangeCustomerUserPassword: "Change password",
	openPermissions: false,
	titlePermissions: "",
};

let activeList=[
	{label: "Yes",value: 1},
	{label: "No",value: 0},
];

let mobilePrintersList=[
	{label: "None",value: "none"},
	{label: "Sunmi",value: "sunmi"},
	{label: "BLP",value: "BLP"},	
];

let inventoryMethodsList=[
	{label: "WEIGHTED AVERAGE",value: "WA"},	
	{label: "FIFO",value: "FIFO"},
	{label: "LIFO",value: "LIFO"},
];

let defaultInputError={
	disableSave: false,
	errorText: "",
	disableSaveCustomerUser: false,
	errorTextCustomerUser: "",
};

let VATonInvoiceList=[
	{label: "Total",value: 0},
	{label: "Per item",value: 1},
];

let invoiceNumberingList=[
	{label: "AUTOMATIC",value: "AUTOMATIC"},
	{label: "MANUAL",value: "MANUAL"},
];

let currentCustomer={};

const Customers = (props) => {

	var timerId=null;
	defaultInputValues.id=(props.customerId?props.customerId:null);

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [inputError, setInputError] = useState(defaultInputError);	
	const [blockScreen, setBlockScreen] = useState(false);
	const [customersList, setCustomersList] = useState([]);
	const [customerUsersList, setCustomerUsersList] = useState([]);
	const [inputValuesCustomerUser, setInputValuesCustomerUser] = useState(defaultInputValuesCustomerUser);
    const location = useLocation();
    const navigate = useNavigate();

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="name") {
			let found=false;
			if(dataValues.customersList.length>0) {
				dataValues.customersList.forEach(element => {
					if(value.toUpperCase()===element.name.toUpperCase()) {
						found=true;
					}
				});
			}
			let tmp={
				disableSave: found,
				errorText: (found===true?"This customer name already exists!":""),
			}
			setInputError(tmp);
		}
		if(name==="filter_customers") {
			setCustomersList(dataValues.customersList.filter((customer)=>((customer.name.toLowerCase().indexOf(value.toLowerCase())>-1))||(value==="")));
		}
		if(name==="filter_customer_users") {
			setCustomerUsersList(dataValues.customerUsersList.filter((user)=>((user.name.toLowerCase().indexOf(value.toLowerCase())>-1))||(value==="")));
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleChecked = (evt) => {
		const {name, checked} = evt.target;
		if(checked) {
			setInputValues({...inputValues,[name]:1});			
		}else{
			setInputValues({...inputValues,[name]:0});			
		}
	}	

	const handleChangeCustomerUser = evt => {
		const {name, value} = evt.target;
		if(name==="user_name") {
			setInputError(defaultInputError);
		}
		setInputValuesCustomerUser({...inputValuesCustomerUser,[name]:value});
	}

	const handleCheckedCustomerUser = (evt) => {
		const {name, checked} = evt.target;
		if(checked) {
			setInputValuesCustomerUser({...inputValuesCustomerUser,[name]:1});			
		}else{
			setInputValuesCustomerUser({...inputValuesCustomerUser,[name]:0});			
		}
	}	

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Add new customer"});
			setInputError(defaultInputError);
		}
		if(what==="edit") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Edit customer"});
			setInputError(defaultInputError);
		}
		if(what==="delete") {
			let url="deleteCustomer";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.name==="") {
				setMessageText("Error: Please fill name!");				
				document.getElementById("name").classList.add("required_input_error");
				return false;
			}
			if(inputValues.address==="") {
				setMessageText("Error: Please fill address!");				
				document.getElementById("address").classList.add("required_input_error");
				return false;
			}
			if((inputValues.vat_registered===1)&&(inputValues.trn==="")) {
				setMessageText("Error: Please fill TRN!");				
				document.getElementById("trn").classList.add("required_input_error");
				return false;
			}
			let url="saveCustomer";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data={...inputValues};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="customer_users") {
			setInputValuesCustomerUser({...inputValuesCustomerUser,elementData});
			currentCustomer=elementData;
			let url="getCustomerUsersList";
			url+="?session_id="+props.session.sessId;
			oper="get_customer_users_list";
			let data={
				customer_id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save_customer_user") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValuesCustomerUser.name==="") {
				setMessageText("Error: Please fill name!");				
				document.getElementById("name").classList.add("required_input_error")
				return false;
			}
			if(inputValuesCustomerUser.user_name==="") {
				setMessageText("Error: Please fill username!");				
				document.getElementById("user_name").classList.add("required_input_error")
				return false;
			}
			if(inputValuesCustomerUser.password==="") {
				setMessageText("Error: Please fill password!");				
				document.getElementById("password").classList.add("required_input_error")
				return false;
			}
			if(inputValuesCustomerUser.confirm_password==="") {
				setMessageText("Error: Please fill password confirmation!");				
				document.getElementById("confirm_password").classList.add("required_input_error")
				return false;
			}
			let url="saveCustomerUser";
			url+="?session_id="+props.session.sessId;
			oper="save_customer_user";
			let data={...inputValuesCustomerUser};
			data.customer_id=currentCustomer.id;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="add_customer_user") {
			setInputValuesCustomerUser(defaultInputValuesCustomerUser);
			setDialogValues({...dialogValues,openAddEditCustomerUser: true,titleAddEditCustomerUser: "Register new user"});
			setInputError(defaultInputError);
		}
		if(what==="edit_customer_user") {
			setInputValuesCustomerUser(elementData);
			setDialogValues({...dialogValues,openAddEditCustomerUser: true,titleAddEditCustomerUser: "Edit user"});
			setInputError(defaultInputError);
		}
		if(what==="password_customer_user") {
			setInputValuesCustomerUser({...inputValuesCustomerUser,id: elementData.id,password: "",confirm_password: ""});
			setDialogValues({...dialogValues,openChangeCustomerUserPassword: true});
		}
		if(what==="save_customer_user_password") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValuesCustomerUser.password==="") {
				setMessageText("Error: Please fill password!");				
				document.getElementById("password").classList.add("required_input_error")
				return false;
			}
			if(inputValuesCustomerUser.confirm_password==="") {
				setMessageText("Error: Please fill password confirmation!");				
				document.getElementById("confirm_password").classList.add("required_input_error")
				return false;
			}
			if(inputValuesCustomerUser.password!==inputValuesCustomerUser.confirm_password) {
				setMessageText("Error: Password and password confirmation are not the same!");				
				return false;
			}
			let url="saveCustomerUserPassword";
			url+="?session_id="+props.session.sessId;
			oper="save_customer_user_password";
			let data={...inputValuesCustomerUser};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete_customer_user") {
			let url="deleteCustomerUser";
			url+="?session_id="+props.session.sessId;
			oper="delete_customer_user";
			let data={
				id: elementData.id,
				customer_id: currentCustomer.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="permissions_customer_user") {
			setInputValuesCustomerUser(elementData);
			setDialogValues({...dialogValues,openPermissions: true,titlePermissions: "Permissions for "+elementData.name});
		}
		if(what==="save_permissions_customer_user") {
			let url="saveCustomerUserPermissions";
			url+="?session_id="+props.session.sessId;
			oper="save_permissions_customer_user";
			let data={...inputValuesCustomerUser};
			data.customer_id=currentCustomer.id;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what===null) {
			navigate("/login");				
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			if(oper==="save_customer_user") {
				let tmp={
					disableSaveCustomerUser: true,
					errorTextCustomerUser: result.error,
				}
				setInputError(tmp);
			}else{
				setMessageText("Error: "+result.error);
			}
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save")||(oper==="delete")) {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,customersList:(result.customersList?result.customersList:[])});
			setCustomersList(result.customersList?result.customersList:[]);
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if((oper==="get_customer_users_list")||(oper==="delete_customer_user")) {
			setDataValues({...dataValues,customerUsersList:(result.data?result.data:[])});
			setCustomerUsersList(result.data?result.data:[]);
			setInputValues(currentCustomer);
			setDialogValues({...dialogValues,openCustomerUsers: true});
			oper="";
		}
		if((oper==="save_customer_user")||(oper==="save_permissions_customer_user")) {
			setDialogValues({...dialogValues,openAddEditCustomerUser: false});
			setDataValues({...dataValues,customerUsersList:(result.data?result.data:[])});
			setCustomerUsersList(result.data?result.data:[]);
			if(oper==="save_permissions_customer_user") {
				setDialogValues({...dialogValues,openPermissions: false});
			}
			oper="";
		}
		if(oper==="save_customer_user_password") {
			setDialogValues({...dialogValues,openChangeCustomerUserPassword: false});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getCustomers";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[props.session.sessId]);

	const addEditDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} className="responsive-dialog-med">
				<ScrollPanel style={{"width":"100%","height":"90%"}}>
					<Grid container spacing={1} style={{"paddingTop":"12px","maxHeight":"72vh"}}>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="name">Name*</label>
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="address">Address*</label>
							<InputText name="address" id="address" style={{"width":"100%"}} value={inputValues.address} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="bank">Bank</label>
							<InputText name="bank" id="bank" style={{"width":"100%"}} value={inputValues.bank} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="bank_account">Bank account</label>
							<InputText name="bank_account" id="bank_account" style={{"width":"100%"}} value={inputValues.bank_account} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="vat_registared">VAT Registered*</label>
							<Dropdown name="vat_registered" id="vat_registered" style={{"width":"100%"}} options={activeList} value={inputValues.vat_registered} onChange={(evt) => handleChange(evt)}/>
						</Grid>						
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="trn">{(inputValues.vat_registered===1?"TRN*":"TRN")}</label>
							<InputText name="trn" id="trn" style={{"width":"100%"}} value={inputValues.trn} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="inventory_method">Inventory method*</label>
							<Dropdown name="inventory_method" id="inventory_method" style={{"width":"100%"}} options={inventoryMethodsList} value={inputValues.inventory_method} onChange={(evt) => handleChange(evt)}/>
						</Grid>						
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="credit_period">Credit period</label>
							<InputNumber name="credit_period" id="credit_period" style={{"width":"100%"}} value={inputValues.credit_period} onChange={(evt) => setInputValues({...inputValues,credit_period:evt.value})} onValueChange={(evt) => setInputValues({...inputValues,credit_period:evt.value})} showButtons mode="decimal"/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} className="field padding-med">
							<label htmlFor="contact_person">Contact person</label>
							<InputText name="contact_person" id="contact_person" style={{"width":"100%"}} value={inputValues.contact_person} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} className="field padding-med">
							<label htmlFor="contact_phone">Contact phone</label>
							<InputText name="contact_phone" id="contact_phone" style={{"width":"100%"}} value={inputValues.contact_phone} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} className="field padding-med">
							<label htmlFor="contact_email">Contact email</label>
							<InputText name="contact_email" id="contact_email" style={{"width":"100%"}} value={inputValues.contact_email} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="emirate">Emirate</label>
							<InputText name="emirate" id="emirate" style={{"width":"100%"}} value={inputValues.emirate} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="country">Country</label>
							<InputText name="country" id="country" style={{"width":"100%"}} value={inputValues.country} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="po_box">PO Box</label>
							<InputText name="po_box" id="po_box" style={{"width":"100%"}} value={inputValues.po_box} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="active">Active</label>
							<Dropdown name="active" id="active" style={{"width":"100%"}} options={activeList} value={inputValues.active} onChange={(evt) => handleChange(evt)}/>
						</Grid>						
						<Grid item xs={12} sm={6} md={3} lg={3} className="field padding-med">
							<label htmlFor="monitoring_email_invoices">Monitor.email invoices</label>
							<InputText name="monitoring_email_invoices" id="monitoring_email_invoices" style={{"width":"100%"}} value={inputValues.monitoring_email_invoices} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3} className="field padding-med">
							<label htmlFor="monitoring_email_expenses">Monitor.email expenses</label>
							<InputText name="monitoring_email_expenses" id="monitoring_email_expenses" style={{"width":"100%"}} value={inputValues.monitoring_email_expenses} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3} className="field padding-med">
							<label htmlFor="monitoring_sms_invoices">Monitor.SMS invoices</label>
							<InputText name="monitoring_sms_invoices" id="monitoring_sms_invoices" style={{"width":"100%"}} value={inputValues.monitoring_sms_invoices} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3} className="field padding-med">
							<label htmlFor="monitoring_sms_expenses">Monitor.SMS expenses</label>
							<InputText name="monitoring_sms_expenses" id="monitoring_sms_expenses" style={{"width":"100%"}} value={inputValues.monitoring_sms_expenses} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={3} className="field padding-med">
							<label htmlFor="invoice_numbering">Invoice numbering</label>
							<Dropdown name="invoice_numbering" id="invoice_numbering" style={{"width":"100%"}} options={invoiceNumberingList} value={inputValues.invoice_numbering} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						{!!(inputValues.invoice_numbering!=="AUTOMATIC") &&
							<React.Fragment>
								<Grid item xs={12} sm={6} md={4} lg={2} className="field padding-med">
									<label htmlFor="walk_in">WALK-IN</label>
									<Dropdown name="walk_in" id="walk_in" style={{"width":"100%"}} options={activeList} value={inputValues.walk_in} onChange={(evt) => handleChange(evt)}/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3} className="field padding-med">
									<label htmlFor="partial_payments">Partial payments</label>
									<Dropdown name="partial_payments" id="partial_payments" style={{"width":"100%"}} options={activeList} value={inputValues.partial_payments} onChange={(evt) => handleChange(evt)}/>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} className="field padding-med">
									<label htmlFor="observations">Observations</label>
									<InputText name="observations" id="observations" style={{"width":"100%"}} value={inputValues.observations} onChange={(evt) => handleChange(evt)}/>
								</Grid>
							</React.Fragment>
						}						
						{!!(inputValues.invoice_numbering==="AUTOMATIC") &&
							<React.Fragment>
								<Grid item xs={12} sm={6} md={3} lg={1} className="field padding-med">
									<label htmlFor="invoice_numbering_start">Start at</label>
									<InputText name="invoice_numbering_start" id="invoice_numbering_start" style={{"width":"100%"}} value={inputValues.invoice_numbering_start} onChange={(evt) => handleChange(evt)}/>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={2} className="field padding-med">
									<label htmlFor="walk_in">WALK-IN</label>
									<Dropdown name="walk_in" id="walk_in" style={{"width":"100%"}} options={activeList} value={inputValues.walk_in} onChange={(evt) => handleChange(evt)}/>
								</Grid>
								<Grid item xs={12} sm={3} md={3} lg={2} className="field padding-med">
									<label htmlFor="partial_payments">Partial payments</label>
									<Dropdown name="partial_payments" id="partial_payments" style={{"width":"100%"}} options={activeList} value={inputValues.partial_payments} onChange={(evt) => handleChange(evt)}/>
								</Grid>
								<Grid item xs={12} sm={9} md={3} lg={4} className="field padding-med">
									<label htmlFor="observations">Observations</label>
									<InputText name="observations" id="observations" style={{"width":"100%"}} value={inputValues.observations} onChange={(evt) => handleChange(evt)}/>
								</Grid>								
							</React.Fragment>
						}						
						<Grid item xs={12} sm={12} md={12} lg={7} style={{"textAlign":"left","marginTop":"21px"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"0px"}}>
								{inputError.errorText}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={5} style={{"textAlign":"right","marginTop":"12px"}}>
							{!!(props.session.role==="admin") &&
								<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")} disabled={inputError.disableSave}/>
							}
							<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
						</Grid>
					</Grid>
				</ScrollPanel>
			</Dialog>
		);
	}

	const customerUsersDialog = () => {
		return(
			<Dialog header={dialogValues.titleCustomerUsers} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openCustomerUsers:false})} className="responsive-dialog-med-big" contentStyle={{"paddingBottom":"15px","minHeight":"501px"}}>
				<div>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={7} sm={8} md={3} lg={3} xl={3} className="field padding-med">
							<InputText name="filter_customer_users" id="filter_customer_users" style={{"width":"100%"}} value={inputValues.filter_customer_users} placeholder="Filter users list..." onChange={(evt) => handleChange(evt)}/>							
						</Grid>						
						<Grid item xs={5} sm={4} md={9} lg={9} xl={9} className="field padding-med text-right">
							<Button label="Add user" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" tooltip="Register user" style={{}} onClick={(evt) => {handleButton(evt,"add_customer_user")}}/>
						</Grid>						
					</Grid>
					<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
						<DataTable
							value={customerUsersList}
							scrollable
							scrollHeight="60vh"
							style={{"minHeight":"63vh"}}
							scrollDirection="both"
							stripedRows
							resizableColumns
							columnResizeMode="fit"
							responsiveLayout="stack"
						>
							<Column field="name" header="Name" style={{"flexGrow":"1","flexBasis":"100px"}} body={tableColTemplateUsers} sortable></Column>
							<Column field="user_name" header="Username" style={{"flexGrow":"1","flexBasis":"100px"}} sortable></Column>
							<Column field="reg_date_parsed" header="Created" style={{"flexGrow":"1","flexBasis":"55px"}} sortable></Column>
							<Column field="buttons" header="Operations" style={{"flexGrow":"1","flexBasis":"260px"}} body={tableColTemplateUsers}></Column>
						</DataTable>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
						<DataTable
							value={customerUsersList}
							responsiveLayout="stack"
						>
							<Column field="name" header="Name" sortable></Column>
							<Column field="user_name" header="Username" sortable ></Column>
							<Column field="reg_date_parsed" header="Created" sortable></Column>
							<Column field="buttons" header="Operations" body={tableColTemplateUsersSmall}></Column>
						</DataTable>
					</Box>
				</div>
			</Dialog>
		);
	}

	const addEditCustomerUserDialog = () => {
		if(inputValuesCustomerUser.id) {
			return(
				<Dialog header={dialogValues.titleAddEditCustomerUser} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEditCustomerUser:false})} className="responsive-dialog-small">
					<Grid container spacing={1} style={{"paddingTop":"12px"}}>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="field padding-med">
							<label htmlFor="name">Name*</label>
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValuesCustomerUser.name} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="field padding-med">
							<label htmlFor="user_name">Username*</label>
							<InputText name="user_name" id="user_name" style={{"width":"100%"}} value={inputValuesCustomerUser.user_name} onChange={(evt) => handleChangeCustomerUser(evt)} disabled={true}/>
						</Grid>
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med">
							<label htmlFor="lock_prices">Lock prices</label>
							<Dropdown name="lock_prices" id="lock_prices" style={{"width":"100%"}} options={activeList} value={inputValuesCustomerUser.lock_prices} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>						
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med">
							<label htmlFor="active">Active</label>
							<Dropdown name="active" id="active" style={{"width":"100%"}} options={activeList} value={inputValuesCustomerUser.active} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>						
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med">
							<label htmlFor="mobile_printer">Mobile printer</label>
							<Dropdown name="mobile_printer" id="mobile_printer" style={{"width":"100%"}} options={mobilePrintersList} value={inputValuesCustomerUser.mobile_printer} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>						
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med">
							<label htmlFor="inventory">Inventory</label>
							<Dropdown name="inventory" id="inventory" style={{"width":"100%"}} options={activeList} value={inputValuesCustomerUser.inventory} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>						
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="field padding-med">
							<label htmlFor="observations">Observations</label>
							<InputText name="observations" id="observations" style={{"width":"100%"}} value={inputValuesCustomerUser.observations} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={7} xl={7} style={{"textAlign":"left","marginTop":"21px"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"0px"}}>
								{inputError.errorTextCustomerUser}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={5} style={{"textAlign":"right","marginTop":"12px"}}>
							<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_customer_user")} disabled={inputError.disableSaveCustomerUser}/>
							<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEditCustomerUser:false})}/>
						</Grid>
					</Grid>
				</Dialog>
			);
		}else{
			return(
				<Dialog header={dialogValues.titleAddEditCustomerUser} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEditCustomerUser:false})} className="responsive-dialog-small">
					<Grid container spacing={1} style={{"paddingTop":"12px"}}>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="field padding-med">
							<label htmlFor="name">Name*</label>
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValuesCustomerUser.name} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="field padding-med">
							<label htmlFor="user_name">Username*</label>
							<InputText name="user_name" id="user_name" style={{"width":"100%"}} value={inputValuesCustomerUser.user_name} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="field padding-med">
							<label htmlFor="password">Password*</label>
							<InputText name="password" id="password" style={{"width":"100%"}} value={inputValuesCustomerUser.password} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="field padding-med">
							<label htmlFor="confirm_password">Confirm passwd*</label>
							<InputText name="confirm_password" id="confirm_password" style={{"width":"100%"}} value={inputValuesCustomerUser.confirm_password} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med">
							<label htmlFor="lock_prices">Lock prices</label>
							<Dropdown name="lock_prices" id="lock_prices" style={{"width":"100%"}} options={activeList} value={inputValuesCustomerUser.lock_prices} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>						
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med">
							<label htmlFor="active">Active</label>
							<Dropdown name="active" id="active" style={{"width":"100%"}} options={activeList} value={inputValuesCustomerUser.active} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>						
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med">
							<label htmlFor="mobile_printer">Mobile printer</label>
							<Dropdown name="mobile_printer" id="mobile_printer" style={{"width":"100%"}} options={mobilePrintersList} value={inputValuesCustomerUser.mobile_printer} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>						
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med">
							<label htmlFor="inventory">Inventory</label>
							<Dropdown name="inventory" id="inventory" style={{"width":"100%"}} options={activeList} value={inputValuesCustomerUser.inventory} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>						
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="field padding-med">
							<label htmlFor="observations">Observations</label>
							<InputText name="observations" id="observations" style={{"width":"100%"}} value={inputValuesCustomerUser.observations} onChange={(evt) => handleChangeCustomerUser(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={7} xl={7} style={{"textAlign":"left","marginTop":"21px"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"0px"}}>
								{inputError.errorTextCustomerUser}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={5} xl={5} style={{"textAlign":"right","marginTop":"12px"}}>
							<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_customer_user")} disabled={inputError.disableSaveCustomerUser}/>
							<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEditCustomerUser:false})}/>
						</Grid>
					</Grid>
				</Dialog>
			);			
		}
	}

	const changeCustomerUserPasswordDialog = () => {
		return(
			<Dialog header={dialogValues.titleChangeCustomerUserPassword} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openChangeCustomerUserPassword:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} className="field padding-med">
						<label htmlFor="password_c">Password</label>
						<Password id="password_c" name="password" style={{"width":"100%"}} value={inputValuesCustomerUser.password} onChange={(evt) => handleChangeCustomerUser(evt)} feedback={false} toggleMask/>
					</Grid>
					<Grid item xs={12} className="field padding-med">
						<label htmlFor="confirm_password">Confirm password</label>
						<Password id="confirm_password" name="confirm_password" style={{"width":"100%"}} value={inputValuesCustomerUser.confirm_password} onChange={(evt) => handleChangeCustomerUser(evt)} feedback={false} toggleMask/>
					</Grid>
					<Grid item xs={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_customer_user_password")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openChangeCustomerUserPassword:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const permissionsDialog = () => {
		return(
			<Dialog header={dialogValues.titlePermissions} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openPermissions:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px","paddingLeft":"12px"}}>
					<Grid item xs={10} sm={10} md={7} lg={4} xl={4} className="field padding-med">
						<p style={(inputValuesCustomerUser.list_invoices===0?{"fontWeight":"300"}:{"fontWeight":"600"})}>List invoices</p>
					</Grid>
					<Grid item xs={2} sm={2} md={5} lg={2} xl={2} className="field padding-med">
						<Checkbox name="list_invoices" onChange={(evt) => handleCheckedCustomerUser(evt)} checked={(inputValuesCustomerUser.list_invoices===1?true:false)}></Checkbox>
					</Grid>
					<Grid item xs={10} sm={10} md={7} lg={4} xl={4} className="field padding-med">
						<p style={(inputValuesCustomerUser.delete_invoices===0?{"fontWeight":"300"}:{"fontWeight":"600"})}>Delete invoices</p>
					</Grid>
					<Grid item xs={2} sm={2} md={5} lg={2} xl={2} className="field padding-med">
						<Checkbox name="delete_invoices" onChange={(evt) => handleCheckedCustomerUser(evt)} checked={(inputValuesCustomerUser.delete_invoices===1?true:false)}></Checkbox>
					</Grid>
					<Grid item xs={10} sm={10} md={7} lg={4} xl={4} className="field padding-med">
						<p style={(inputValuesCustomerUser.list_clients===0?{"fontWeight":"300"}:{"fontWeight":"600"})}>List clients</p>
					</Grid>
					<Grid item xs={2} sm={2} md={5} lg={2} xl={2} className="field padding-med">
						<Checkbox name="list_clients" onChange={(evt) => handleCheckedCustomerUser(evt)} checked={(inputValuesCustomerUser.list_clients===1?true:false)}></Checkbox>
					</Grid>
					<Grid item xs={10} sm={10} md={7} lg={4} xl={4} className="field padding-med">
						<p style={(inputValuesCustomerUser.delete_clients===0?{"fontWeight":"300"}:{"fontWeight":"600"})}>Delete clients</p>
					</Grid>
					<Grid item xs={2} sm={2} md={5} lg={2} xl={2} className="field padding-med">
						<Checkbox name="delete_clients" onChange={(evt) => handleCheckedCustomerUser(evt)} checked={(inputValuesCustomerUser.delete_clients===1?true:false)}></Checkbox>
					</Grid>
					<Grid item xs={10} sm={10} md={7} lg={4} xl={4} className="field padding-med">
						<p style={(inputValuesCustomerUser.payments===0?{"fontWeight":"300"}:{"fontWeight":"600"})}>Process payments</p>
					</Grid>
					<Grid item xs={2} sm={2} md={5} lg={2} xl={2} className="field padding-med">
						<Checkbox name="payments" onChange={(evt) => handleCheckedCustomerUser(evt)} checked={(inputValuesCustomerUser.payments===1?true:false)}></Checkbox>
					</Grid>
					<Grid item xs={10} sm={10} md={7} lg={4} xl={4} className="field padding-med">
						<p>Max discount</p>
					</Grid>
					<Grid item xs={2} sm={2} md={5} lg={2} xl={2} className="field padding-med">
						<InputText name="max_discount" id="max_discount" style={{"width":"100%","marginTop":"-6px"}} value={inputValuesCustomerUser.max_discount} onChange={(evt) => handleChangeCustomerUser(evt)}/>
					</Grid>
					<Grid item xs={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_permissions_customer_user")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openPermissions:false})}/>
					</Grid>					
				</Grid>
			</Dialog>
		);
	}

	const tableColTemplate = (row_data,column) => {
		if(column.field==="name") {
			if(row_data.active===1) {
				return(
					<span>
						{row_data.name}
					</span>
				)
			}else{
				return(
					<span>
						<span>
							{row_data.name}
						</span>
						<Tag severity="danger" style={{"marginLeft":"12px"}} value="INACTIVE"></Tag>
					</span>
				)
			}
		}
		if(column.field==="buttons") {
			return(
				<span>
					{!!(props.session.edit_customers===1) &&
						<Button label="Edit customer" icon="pi pi-pencil" tooltip="Edit customer" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
					}
					{!!(props.session.edit_customers===0) &&
						<Button label="Edit customer" icon="pi pi-pencil" tooltip="Edit customer" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
					}
					{!!(props.session.edit_customers_users===1) &&
						<Button label="Users" icon="pi pi-user" tooltip="Registered users" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"customer_users",row_data)}/>
					}
					{!!(props.session.edit_customers_users===0) &&
						<Button label="Users" icon="pi pi-user" tooltip="Registered users" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
					}
					{!!(props.session.role==="admin") &&
						<Button label="Delete" icon="pi pi-trash" tooltip="Delete customer" className="p-button-sm p-button-raised p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
					}
					{!!(props.session.role!=="admin") &&
						<Button label="Delete" icon="pi pi-trash" tooltip="Delete customer" className="p-button-sm p-button-raised p-button-danger" disabled={true} onClick={(evt) => confirm(evt,"null",row_data)}/>
					}
				</span>
			);
		}
	}

	const tableColTemplateSmall = (row_data,column) => {
		if(column.field==="buttons") {
			return(
				<span>
					{!!(props.session.edit_customers===1) &&
						<Button icon="pi pi-pencil" tooltip="Edit customer" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
					}
					{!!(props.session.edit_customers===0) &&
						<Button icon="pi pi-pencil" tooltip="Edit customer" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
					}
					{!!(props.session.edit_customers_users===1) &&
						<Button icon="pi pi-user" tooltip="Registered users" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"customer_users",row_data)}/>
					}
					{!!(props.session.edit_customers_users===0) &&
						<Button icon="pi pi-user" tooltip="Registered users" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
					}
					{!!(props.session.role==="admin") &&
						<Button icon="pi pi-trash" tooltip="Delete customer" className="p-button-sm p-button-raised p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
					}
					{!!(props.session.role!=="admin") &&
						<Button icon="pi pi-trash" tooltip="Delete customer" className="p-button-sm p-button-raised p-button-danger" disabled={true} onClick={(evt) => confirm(evt,"null",row_data)}/>
					}
				</span>
			);
		}
	}

	const tableColTemplateUsers = (row_data,column) => {
		if(column.field==="name") {
			if(row_data.active===1) {
				return(
					<span>
						{row_data.name}
					</span>
				)
			}else{
				return(
					<span>
						<span>
							{row_data.name}
						</span>
						<Tag severity="danger" style={{"marginLeft":"12px"}} value="INACTIVE"></Tag>
					</span>
				)
			}
		}
		if(column.field==="buttons") {
			if(props.session.role==="admin") {
				return(
					<span>
						<Button label="Permissions" icon="pi pi-clone" tooltip="User's permissions" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"permissions_customer_user",row_data)}/>
						<Button label="Password" icon="pi pi-key" tooltip="Change password" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"password_customer_user",row_data)}/>
						<Button label="Edit" icon="pi pi-pencil" tooltip="Edit customer user" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"edit_customer_user",row_data)}/>
						<Button label="Delete" icon="pi pi-trash" tooltip="Delete customer user" className="p-button-sm p-button-raised p-button-danger" onClick={(evt) => confirm(evt,"delete_customer_user",row_data)}/>
					</span>
				);
			}else{
				return(
					<span>
						<Button label="Permissions" icon="pi pi-clone" tooltip="User's permissions" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button label="Password" icon="pi pi-key" tooltip="Change password" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button label="Edit" icon="pi pi-pencil" tooltip="Edit customer user" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button label="Delete" icon="pi pi-trash" tooltip="Delete customer user" className="p-button-sm p-button-raised p-button-danger" disabled={true} onClick={(evt) => confirm(evt,"null",row_data)}/>
					</span>
				);
			}		
		}
	}

	const tableColTemplateUsersSmall = (row_data,column) => {
		if(column.field==="buttons") {
			if(props.session.role==="admin") {
				return(
					<span>
						<Button icon="pi pi-clone" tooltip="Users's permissions" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"permissions_customer_user",row_data)}/>
						<Button icon="pi pi-key" tooltip="Change password" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"password_customer_user",row_data)}/>
						<Button icon="pi pi-pencil" tooltip="Edit customer user" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit_customer_user",row_data)}/>
						<Button icon="pi pi-trash" tooltip="Delete customer user" className="p-button-sm p-button-raised p-button-danger" onClick={(evt) => confirm(evt,"delete_customer_user",row_data)}/>
					</span>
				);
			}else{
				return(
					<span>
						<Button icon="pi pi-clone" tooltip="Users's permissions" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button icon="pi pi-key" tooltip="Change password" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button icon="pi pi-pencil" tooltip="Edit customer user" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button icon="pi pi-trash" tooltip="Delete customer user" className="p-button-sm p-button-raised p-button-danger" disabled={true} onClick={(evt) => confirm(evt,"null",row_data)}/>
					</span>
				);
			}
		}
	}

	const confirm = (evt,what,elementData) => {
		if((what==="delete")||(what==="delete_customer_user")) {
			confirmDialog({
				message: "Are you sure you want to delete?",
				header: "Confirm delete",
				icon: "pi pi-exclamation-triangle",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<div>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={7} sm={8} md={3} lg={3} xl={3} className="field padding-med">
							<InputText name="filter_customers" id="filter_customers" style={{"width":"100%"}} value={inputValues.filter_customers} placeholder="Filter customers list..." onChange={(evt) => handleChange(evt)}/>							
						</Grid>						
						<Grid item xs={5} sm={4} md={9} lg={9} xl={9} className="field padding-med text-right">
							{!!(props.session.edit_customers===1) &&							
								<Button label="Add customer" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" style={{}} onClick={(evt) => {handleButton(evt,"add")}}/>
							}
						</Grid>						
					</Grid>
					<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
						<div className="card">
							<DataTable
								value={customersList}
								scrollable
								scrollHeight="60vh"
								style={{"minHeight":"63vh"}}
								scrollDirection="both"
								stripedRows
								resizableColumns
								columnResizeMode="fit"
								responsiveLayout="stack"
							>
								<Column field="name" header="Name" style={{"flexGrow":"1","flexBasis":"190px"}} body={tableColTemplate} sortable></Column>
								<Column field="reg_date_parsed" header="Created" style={{"flexGrow":"0","flexBasis":"180px"}} sortable></Column>
								<Column field="contact_email" header="Email" style={{"flexGrow":"1","flexBasis":"190px"}} sortable></Column>
								<Column field="contact_phone" header="Phone" style={{"flexGrow":"1","flexBasis":"60px"}} sortable></Column>
								<Column field="buttons" header="Operations" style={{"flexGrow":"0","flexBasis":"303px"}} body={tableColTemplate}></Column>
							</DataTable>
						</div>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
						<div className="card">
							<h5>Customers list</h5>
							<DataTable
								value={customersList}
								responsiveLayout="stack"
							>
								<Column field="name" header="Name" sortable></Column>
								<Column field="reg_date_parsed" header="Created" sortable></Column>
								<Column field="contact_email" header="Email"></Column>
								<Column field="contact_phone" header="Phone"></Column>
								<Column field="buttons" header="Operations" body={tableColTemplateSmall}></Column>
							</DataTable>
						</div>
					</Box>
				</div>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openCustomerUsers && customerUsersDialog()}
				{dialogValues.openAddEditCustomerUser && addEditCustomerUserDialog()}
				{dialogValues.openChangeCustomerUserPassword && changeCustomerUserPasswordDialog()}
				{dialogValues.openPermissions && permissionsDialog()}
            </BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default Customers;


