import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import Message from "./Helpers/Message.js";
import {Toolbar} from "primereact/toolbar";
import {Dialog} from "primereact/dialog";
import {Box} from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import MenuIcons from "./MenuIcons.js";
import Dashboard from "./Sections/Dashboard.js";
import LocalUsers from "./Sections/LocalUsers.js";
import Customers from "./Sections/Customers.js";
import ChooseCustomer from "./Sections/ChooseCustomer.js";
import Inventory from "./Sections/Inventory.js";
import ErrorPage from "./Sections/ErrorPage.js";
import UserSettings from "./Sections/UserSettings";
import ActivityLog from "./Sections/ActivityLog.js";
import Invoices from "./Sections/Invoices.js";
import Expenses from "./Sections/Expenses.js";
import {fetch_url_post} from "../Sources/js/app.js";

let oper="";
let defaultDialogValues={
	openUserSettings: false,
	titleUserSettings: "User settings",
	openChooseCustomer: false,
	titleChooseCustomer: "Choose customer",
};

export default function MainPage(props) {

	const dataOk=(props.session.sessId?true:false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [menuSections, setMenuSections] = useState([]);
	const [messageText, setMessageText] = useState(null);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [currentCustomer, setCurrentCustomer] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

	const handleButton = (evt,what) => {
		if(what==="menu") {
			setMenuOpen(!menuOpen);
		}
		if(what==="user_settings") {
			setDialogValues({...dialogValues,openUserSettings: true});
		}
		if(what==="change_customer") {
			setDialogValues({...dialogValues,openChooseCustomer: true});
		}
		if(what==="dashboard") {
			navigate("/");
		}
		if(what==="reset_current_customer") {
			setCurrentCustomer({});
			navigate("/");
		}
	}

	const success_handler = (result) => {
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="getMenuSections") {
			setMenuSections(result.data);
		}
		if(oper==="validateSession") {
			if(result.is_auth===1) {
				props.loginHandler(result);
			}else{
				navigate("/login");
			}
		}
		oper="";
	}

	const fail_handler = () => {
		setMessageText("Connection error. Please try again later!");
	}

	useEffect(() => {
//		console.log("got useEffect() in main with "+props.session.sessId);
		window.localStorage.removeItem("docReturnPathXa7");
		window.localStorage.setItem("docReturnPathXa7",(location.pathname?location.pathname:"/"));
		if(!props.session.sessId) {
			if(!window.localStorage.getItem("docSessIdXa7")) {
//				console.log("Implicit redirect to login...");
				navigate("/login");						
			}
			validateSession(window.localStorage.getItem("docSessIdXa7"));
		}else{
			oper="getMenuSections";
			let url="getMenuSections";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}
	},[props.session.sessId]);

	const validateSession = (sessionId) => {
//		console.log("Try to validate session...");
		oper="validateSession";
		let url="validateSession";
		url+="?session_id="+sessionId;
		let data={};
		fetch_url_post(url,data,success_handler,fail_handler);
	}

	const handleSection = (evt,section) => {
		//used only for menu sections and appbar sections
		setMenuOpen(false);
		switch(section) {
			case "Dashboard":
				navigate("/");
				break;
			case "Local users":
				navigate("/localUsers");
				break;
			case "Customers":
				navigate("/customers");
				break;
			case "Activity log":
				navigate("/activityLog");
				break;
		}
	}

	const getCurrentSection = () => {
		//used to render current content from all sources
		switch(location.pathname) {
			case "/localUsers":
				return(
					<LocalUsers session={props.session} customer={currentCustomer}/>
				);
			case "/customers":
				return(
					<Customers session={props.session} customer={currentCustomer}/>
				);
			case "/inventory":
				if(currentCustomer.id) {
					return(
						<Inventory session={props.session} customer={currentCustomer}/>
					);
				}else{
					navigate("/");
				}
			case "/activityLog":
				return(
					<ActivityLog session={props.session}/>
				);
			case "/expenses":
				return(
					<Expenses session={props.session} customer={currentCustomer}/>
				);
			case "/invoices":
				return(
					<Invoices session={props.session} customer={currentCustomer}/>
				);
			default:
				return(
					<Dashboard session={props.session} customer={currentCustomer}/>
				);
		}
	}

	const translateSection = () =>{
		//used for main dialog title
		switch(location.pathname) {
			case "/localUsers":
				return "Users";
			case "/customers":
				return "Customers";
			case "/inventory":
				return "Inventory";
			case "/activityLog":
				return "Activity log";
			case "/invoices":
				return "Customer's invoices";
			case "/expenses":
				return "Customer's expenses";
			default:
				return "Dashboard";
		}
	}

	const selectCustomer = (customer) => {
		setCurrentCustomer(customer);
		setDialogValues(defaultDialogValues);
		navigate("/");
	}

    const leftToolBarContents = (
        <React.Fragment>
			<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
				{!!(menuOpen) &&
					<Button label="Menu" icon="pi pi-times" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"8px"}} onClick={(evt) => handleButton(evt,"menu")}/>
				}
				{!!(!menuOpen) &&
					<Button label="Menu" icon="pi pi-bars" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"8px"}} onClick={(evt) => handleButton(evt,"menu")}/>
				}
				{!!(currentCustomer.id) &&
					<Button label="Change current customer" icon="pi pi-book" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"8px"}} onClick={(evt) => handleButton(evt,"change_customer")}/>
				}
				{!!(!currentCustomer.id) &&
					<Button label="Choose current customer" icon="pi pi-book" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"8px"}} onClick={(evt) => handleButton(evt,"change_customer")}/>
				}				
				<p style={{"marginRight":"12px","display":"inline","lineHeight":"2.1","color":"#FFFFFF"}}>{(currentCustomer.name?"Current customer: "+currentCustomer.name:"No current customer...")}</p>
				{!!(currentCustomer.id) &&
					<Button icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt) => handleButton(evt,"reset_current_customer")}/>
				}
				<Button label="Dashboard" icon="pi pi-home" className="p-button-sm p-button-success p-button-raised" style={{"marginLeft":"24px"}} onClick={(evt) => handleButton(evt,"dashboard")}/>
			</Box>
			<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
				<Button icon="pi pi-bars" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"menu")}/>
				<Button icon="pi pi-book" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"change_customer")}/>
				<p style={{"marginRight":"12px","display":"inline","lineHeight":"2.1","color":"#FFFFFF"}}>{(currentCustomer.name?currentCustomer.name:"Choose a customer...")}</p>
			</Box>
        </React.Fragment>
    );

    const rightToolBarContents = (
        <React.Fragment>
			<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
				<p style={{"marginRight":"12px","display":"inline","lineHeight":"2.1","color":"#FFFFFF"}}>{props.session.name}</p>
				<Button label="Settings" icon="pi pi-cog" style={{"marginRight":"9px"}} className="p-button-sm p-button-success p-button-raised" onClick={(evt) => handleButton(evt,"user_settings")}/>
				<Button label="Logout" icon="pi pi-power-off" className="p-button-sm p-button-danger p-button-raised" onClick={(evt) => props.logoutHandler(evt)}/>
			</Box>
			<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
				<Button icon="pi pi-cog" style={{"marginRight":"9px"}} className="p-button-sm p-button-success p-button-raised" onClick={(evt) => handleButton(evt,"user_settings")}/>
				<Button icon="pi pi-power-off" className="p-button-sm p-button-success p-button-raised" onClick={(evt) => props.logoutHandler(evt)}/>
			</Box>
        </React.Fragment>
    );

	const userSettingsDialog = () => {
		return(
			<Dialog header={dialogValues.titleUserSettings} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openUserSettings:false})} className="responsive-dialog-med" contentStyle={{"paddingBottom":"15px"}}>
				<UserSettings session={props.session}/>
			</Dialog>
		);
	}

	const chooseCustomerDialog = () => {
		return(
			<Dialog header={dialogValues.titleChooseCustomer} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openChooseCustomer:false})} className="responsive-dialog-small" contentStyle={{"paddingBottom":"15px","minHeight":"102px"}}>
				<ChooseCustomer session={props.session} customer={currentCustomer} selectCustomerHandler={selectCustomer}/>
			</Dialog>
		);
	}

	if(dataOk) {
		return(
			<div style={{}}>
				<Sidebar visible={menuOpen} onHide={() => setMenuOpen(false)} style={{"width":"270px","marginTop":"33px","height":"843px","borderRadius":"3px"}}>
					<h3 style={{"width":"100","textAlign":"center","marginBottom":"30px"}}>Main menu</h3>
					{menuSections && menuSections.map((section,index) => 
						<div key={index} onClick={(evt) => handleSection(evt,section.name)}>
							<MenuIcons section={section.name}/>
						</div>
					)}
				</Sidebar>
				<Toolbar left={leftToolBarContents} right={rightToolBarContents} className="main-toolbar" style={{}}/>
				<div style={{"minHeight":"87.9vh","padding":"15px","paddingLeft":"12px","paddingRight":"0px"}}>
					<div style={{"textAlign":"left","marginLeft":"12px","color":"#000000"}}>
						<h4>{translateSection()}</h4>
					</div>
					{getCurrentSection()}				
				</div>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openUserSettings && userSettingsDialog()}
				{dialogValues.openChooseCustomer && chooseCustomerDialog()}
			</div>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
}
