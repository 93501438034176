import React from "react";
import {useState} from "react";
import {Route,useLocation,Routes,useNavigate} from "react-router-dom";
import {locale,addLocale} from "primereact/api";
import Container from "@mui/material/Container";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./Sources/css/App.css";
import MainPage from "./Components/MainPage.js";
import LoginForm from "./Components/LoginForm";

const App = (props) => {

	const [session, setSession] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
	
	const loginHandler = (auth) => {
		setSession(auth.session);
		window.localStorage.removeItem("docSessIdXa7");
		window.localStorage.setItem("docSessIdXa7",auth.session.sessId);
		if(window.localStorage.getItem("docReturnPathXa7")) {
			navigate(window.localStorage.getItem("docReturnPathXa7"));
			window.localStorage.removeItem("docReturnPathXa7");
		}else{
			navigate("/");
		}
	}

//	addLocale("ro", {
//		firstDayOfWeek: 1,
//		dayNames: ["duminica","luni","marti","miercuri","joi","vineri","sambata"],
//		dayNamesShort: ["dum", "lun", "mar", "mie", "joi", "vin", "sam"],
//		dayNamesMin: ["D", "L", "Ma", "Mi", "J", "V", "S"],
//		monthNames: ["Ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"],
//		monthNamesShort: ["ian", "feb", "mar", "apr", "mai", "iun", "iul", "aug", "sep", "oct", "nov", "dec"],
//		today: "Astazi",
//		clear: "Curata"
//	});
	
//	locale("ro");

	const maxWidth="1710px";

	const logoutHandler = () => {
		window.localStorage.removeItem("docSessIdXa7");
		window.localStorage.removeItem("docReturnPathXa7");
		setSession({});
		navigate("/login");
	}

    return(
		<Container maxWidth="xl" disableGutters>
			<Routes>
				<Route path="/login" element={<LoginForm session={session} loginHandler={loginHandler}/>}/>
				<Route path="*" element={<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler}/>}/>				
			</Routes>
		</Container>
	)
};

export default App;
