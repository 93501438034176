import React from "react";
import {Button} from 'primereact/button';

export default function MenuIcons(props) {

	if(props.section==="Local users") {
		return(
			<Button label={props.section} icon="pi pi-user" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Customers") {
		return(
			<Button label={props.section} icon="pi pi-building" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Dashboard") {
		return(
			<Button label={props.section} icon="pi pi-home" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Activity log") {
		return(
			<Button label={props.section} icon="pi pi-book" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	return(
		<Button label={props.section} icon="pi pi-user" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
	);
}

