import React from "react";
import {useState} from "react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import Message from "./Helpers/Message.js";
import {fetch_url_post,Crypt_pass} from "../Sources/js/app.js";

const defaultInputValues={
	utl: "",
	ps: "",
}

let oper="";

const LoginForm = (props) => {

	const [inputValues, setInputValues] = React.useState(defaultInputValues);
	const [messageText, setMessageText] = useState(null);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what) => {
		if(what==="login") {
			if((inputValues.utl.length<3)||(inputValues.ps.length<3)) {
				setMessageText("Error: Please fill username and password!");
				return false;
			}
			oper="login";
			let data={
				utl: inputValues.utl,
				mdp: Crypt_pass(inputValues.utl,inputValues.ps),
			};
			fetch_url_post("login",data,success_handler,fail_handler);
		}
		if(what==="reset") {
			setInputValues(defaultInputValues);
		}
	}

	const success_handler=(result)=> {
		if(result.ok<1) {
			setMessageText("Error: "+result.error);	
		}
		if(result.message) {
			setMessageText(result.message);
		}
		if(oper==="login") {
			if(result.is_auth===1) {
				props.loginHandler(result);
			}else{
				setMessageText("Authentication failed. Incorrect username or password!");
			}
			oper="";
		}
	}

	const fail_handler=()=>{
		setMessageText("Connection error please try again later!");
		return false;			
	}

    return (
        <div className="login-body" style={{"backgroundImage":"none"}}>
            <div className="login-panel ui-fluid">
                <div className="login-panel-header">
                    <img src="assets/layout/images/login/MFA_logo.png" alt=""/>
                </div>
                <div className="login-panel-content">
                    <div className="grid">
                        <div className="col-12" style={{"textAlign":"center","marginTop":"-12px"}}>
							<img src="assets/layout/images/login/MFA_logo_name.png" alt=""/>
                        </div>
                        <div className="field col-12 padding-min">
							<label htmlFor="utl">Username</label>
							<InputText name="utl" id="utl" type="text" style={{"width":"100%"}} v-model="username" value={inputValues.utl} onChange={(evt) => handleChange(evt)} />
                        </div>
                        <div className="field col-12 padding-min">
							<label htmlFor="ps">Password</label>
							<Password name="ps" id="ps" style={{"width":"100%"}} inputStyle={{"width":"100%"}} value={inputValues.ps} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
                        </div>
                        <div className="col-12" style={{"textAlign":"right"}}>
							<Button label="Login" icon="pi pi-unlock" className="p-button-small p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"login")}/>
							<Button label="Reset" icon="pi pi-times" className="p-button-small p-button-raised p-button-danger" style={{}} onClick={(evt) => handleButton(evt,"reset")}/>
                        </div>
                    </div>
                </div>
            </div>
			{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
        </div>
    );
};

export default LoginForm;
