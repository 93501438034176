import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import Grid from "@mui/material/Grid";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Divider} from "primereact/divider";
import {ScrollPanel} from "primereact/scrollpanel";
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultDataValues={
	userDetails: {},
};

let defaultInputValues={
	current_password: "",
	password: "",
	confirm_password: "",
};

const UserSettings = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(true);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [dataValues, setDataValues] = useState(defaultDataValues);
    const location = useLocation();
    const navigate = useNavigate();

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what) => {
		if(what==="change_password") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.current_password==="") {
				setMessageText("Error: Please fill current_password!");				
				document.getElementById("current_password").classList.add("required_input_error");
				return false;
			}
			if(inputValues.password==="") {
				setMessageText("Error: Please fill password!");				
				document.getElementById("password").classList.add("required_input_error");
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Error: Please fill password confirmation!");				
				document.getElementById("confirm_password").classList.add("required_input_error");
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Error: Password and confirm must be identical!");				
				document.getElementById("password").classList.add("required_input_error");
				document.getElementById("confirm_password").classList.add("required_input_error");
				return false;
			}
			let url="saveUserPassword";
			url+="?session_id="+props.session.sessId;
			oper="change_password";
			let data={...inputValues};
			data.confirm=1;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="get") {
			setDataValues({...dataValues,userDetails:(result.data?result.data:{})});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="change_password") {
			if(result.message.indexOf("rror")===-1) {
				setInputValues(defaultInputValues);
			}
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getUserDetails";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[props.session.sessId]);

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<ScrollPanel style={{"width":"100%","height":"90%"}}>
					<div style={{"maxHeight":"69vh"}}>
						<Grid container spacing={1} style={{"paddingTop":"12px","paddingLeft":"12px"}}>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="field padding-med">
								<label htmlFor="name">Name</label>
								<InputText name="name" id="name" style={{"width":"100%"}} value={dataValues.userDetails.name}/>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="field padding-med">
								<label htmlFor="user_name">Username</label>
								<InputText name="user_name" id="user_name" style={{"width":"100%"}} value={dataValues.userDetails.user_name}/>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="field padding-med">
								<label htmlFor="reg_date_parsed">Created at</label>
								<InputText name="reg_date_parsed" id="reg_date_parsed" style={{"width":"100%"}} value={dataValues.userDetails.reg_date_parsed}/>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="field padding-med">
								<label htmlFor="name">Emai address</label>
								<InputText name="email" id="email" style={{"width":"100%"}} value={dataValues.userDetails.email}/>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="field padding-med">
								<label htmlFor="function">Function</label>
								<InputText name="function" id="function" style={{"width":"100%"}} value={dataValues.userDetails.function}/>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="field padding-med">
								<label htmlFor="role">User role</label>
								<InputText name="role" id="role" style={{"width":"100%"}} value={dataValues.userDetails.role}/>
							</Grid>
						</Grid>
						<Divider layout="horizontal" align="center">
							<span className="p-tag">Change password</span>
						</Divider>
						<Grid container spacing={1} style={{"paddingTop":"12px","paddingLeft":"12px"}}>
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="field padding-med">
								<label htmlFor="current_password">Current password</label>
								<Password name="current_password" id="current_password" style={{"width":"100%"}} inputStyle={{"width":"100%"}} value={inputValues.current_password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="field padding-med">
								<label htmlFor="password">Password</label>
								<Password name="password" id="password" style={{"width":"100%"}} inputStyle={{"width":"100%"}} value={inputValues.password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="field padding-med">
								<label htmlFor="confirm_password">Confirm password</label>
								<Password name="confirm_password" id="confirm_password" style={{"width":"100%"}} inputStyle={{"width":"100%"}} value={inputValues.confirm_password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="field padding-med text-right">
								<Button label="Change password" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginTop":"24px"}} onClick={(evt) => handleButton(evt,"change_password")}/>
							</Grid>
						</Grid>
					</div>
				</ScrollPanel>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default UserSettings;
