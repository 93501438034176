import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Box} from "@mui/system";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {ConfirmDialog} from "primereact/confirmdialog";
import {confirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import InventoryOperations from "./InventoryOperations.js";
import {fetch_url_post,get_monday,format_date,fetch_download} from "../../Sources/js/app.js";
import {get_api_path} from "../../Sources/js/paths.js";

let oper="";

let defaultInputValues={
	period: "today",
	start_date: new Date(),
	stop_date: new Date(),
	filter_name_expenses: "",
	expense_value: "",
};

let defaultDataValues={
	dashboardList: [],
	invoicesList: [],
	expensesList: [],
};

let defaultDialogValues={
	openListExpenses: false,
	titleListExpenses: "",
	openSetExpenseValue: false,
	titleSetExpenseValue: "",
	openInventory: false,
	titleInventory: "",
};

let periodsList=[
	{label: "Today",value: "today"},
//	{label: "Yesterday",value: "yesterday"},
	{label: "Current week",value: "c_week"},
//	{label: "Pervious week",value: "p_week"},	
	{label: "Current month",value: "c_month"},
//	{label: "Previous month",value: "p_month"},
	{label: "Custom interval",value: "custom"},
]

let currentExpense={};

const Expenses = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [customDates, setCustomDates] = useState(false);
	const [dashboardExpensesList, setDashboardExpensesList] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
    const [rowClick, setRowClick] = useState(true);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [pdfUrl, setPdfUrl] = useState(null);
	const [currentElement, setCurrentElement] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="period") {
			if(value) {
				//form date
				let start_date="";
				let d=new Date();
				setCustomDates(false);
				if(value==="today") {
					start_date=format_date(d,"yyyy-mm-dd");
				}
				if(value==="yesterday") {
					start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
				}
				if(value==="c_week") {
					start_date=format_date(get_monday(d),"yyyy-mm-dd");
				}
				if(value==="p_week") {
					start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
				}
				if(value==="c_month") {
					start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
				}
				if(value==="p_month") {
					start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
				}
				if(value==="custom") {
					setCustomDates(true);
				}
				if(value!=="custom") {
					oper="get";
					let url="initGeneralDashboardExpenses";
					url+="?session_id="+props.session.sessId;
					let data={
						customer_id: props.customer.id,
						start_date: start_date,
					};
					fetch_url_post(url,data,success_handler,fail_handler);				
				}
			}
		}
		if(name==="filter_name_expenses") {
			setDashboardExpensesList(dataValues.dashboardExpensesList.filter((item)=>((item.name.toLowerCase().indexOf(value.toLowerCase())>-1))||(value==="")));
		}
		if(name==="expense_value") {
			if(!evt.target.validity.valid) {
				window.alert("Only numbers are allowed!");
				return false;								
			}
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="update") {
			let data={};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				if((inputValues.start_date)&&(inputValues.stop_date)) {
					data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
					data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
				}else{
					setMessageText("Error: Select time interval!");				
				}
			}
			data.customer_id=props.customer.id;
			oper="get";
			let url="initGeneralDashboardExpenses";
			url+="?session_id="+props.session.sessId;
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="list_expenses") {
			oper="get_expenses_list";
			let url="getExpensesList";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
			};
			console.log(data);
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="download_expense_file") {
			let url="getFile";
			let oper="download";
			url+="?session_id="+props.session.sessId+"&file="+elementData.filename_on_disk;
			let data={};
			fetch_download(url,data,elementData.filename,success_handler);
			setBlockScreen(true);
			setMessageText("Downloading file, please wait...");
		}
		if(what==="export_xls") {
			let url="getXLSExpensesList";
			let oper="download";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
				currency: elementData.currency,
				customer_name: elementData.name,
			};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_download(url,data,"expenses_report",success_handler);
			setBlockScreen(true);
			setMessageText("Downloading file, please wait...");
		}
		if(what==="set_expense_value") {
			setCurrentElement(elementData);
			setDialogValues({...dialogValues,openSetExpenseValue: true,titleSetExpenseValue: "Set expense value"});
		}
		if(what==="save_expense_value") {
			oper="save_expense_value";
			let url="saveExpenseValue";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
				id: currentElement.id,
				value: inputValues.expense_value,
			};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="export_selection_xls") {
			let url="getXLSExpensesListSummary";
			let oper="download";
			url+="?session_id="+props.session.sessId;
			let data={
				expenses_list: selectedItems,
			};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_download(url,data,"expenses_report",success_handler);
			setMessageText("Downloading file, please wait...");
		}
		if(what==="inventory") {
			currentExpense=elementData;
			setDialogValues({...dialogValues,openInventory: true,titleInventory: "Inventory operations for file "+elementData.filename});
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="init")||(oper==="get")) {
			setDataValues({...dataValues,dashboardExpensesList:(result.dashboardExpensesList?result.dashboardExpensesList:[])});
			setDashboardExpensesList(result.dashboardExpensesList?result.dashboardExpensesList.filter((item)=>((item.name.toLowerCase().indexOf(inputValues.filter_name_expenses.toLowerCase())>-1))||(inputValues.filter_name_expenses==="")):[]);
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="get_expenses_list") {
			setDataValues({...dataValues,expensesList:(result.expensesList?result.expensesList:[])});
			setDialogValues({...dialogValues,openListExpenses: true,titleListExpenses: "Expenses list"});
			oper="";
		}
		if(oper==="download") {
			setMessageText(null);
			setBlockScreen(false);
			oper="";
		}
		if(oper==="save_expense_value") {
			setDataValues({...dataValues,dashboardExpensesList:(result.dashboardExpensesList?result.dashboardExpensesList:[])});
			setDashboardExpensesList(result.dashboardExpensesList?result.dashboardExpensesList.filter((item)=>((item.name.toLowerCase().indexOf(inputValues.filter_name_expenses.toLowerCase())>-1))||(inputValues.filter_name_expenses==="")):[]);
			setDataValues({...dataValues,expensesList:(result.expensesList?result.expensesList:[])});
			setDialogValues({...dialogValues,openSetExpenseValue: false,titleSetExpenseValue: ""});
			setInputValues({...inputValues,expense_value:""});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init";
			let url="initGeneralDashboardExpenses";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
				start_date: format_date(new Date(),"yyyy-mm-dd"),
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

	const tableColTemplateGeneralExpenses = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"6px"}}>{row_data[column.field]}</span>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="View expenses" icon="pi pi-list" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={(row_data.expenses_no>0?false:true)} onClick={(evt) => handleButton(evt,"list_expenses",row_data)}/>
					<Button label="Export as XLS" icon="pi pi-file-excel" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"export_xls",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateGeneralExpensesSmall = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"6px"}}>{row_data[column.field]}</span>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button tooltip="View expenses" icon="pi pi-list" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={(row_data.expenses_no>0?false:true)} onClick={(evt) => handleButton(evt,"list_expenses",row_data)}/>
					<Button tooltip="Export as XLS" icon="pi pi-file-excel" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"export_xls",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateExpenses = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"6px"}}>{row_data[column.field]}</span>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="Download" icon="pi pi-download" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"download_expense_file",row_data)}/>
					<Button label="Inventory" icon="pi pi-database" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"inventory",row_data)}/>
					<Button label="Set value" icon="pi pi-dollar" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"set_expense_value",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateExpensesSmall = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"6px"}}>{row_data[column.field]}</span>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button tooltip="Download" icon="pi pi-download" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"download_expense_file",row_data)}/>
					<Button tooltip="Inventory" icon="pi pi-database" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"inventory",row_data)}/>
					<Button tooltip="Set value" icon="pi pi-dollar" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"set_expense_value",row_data)}/>
				</span>
			);
		}
	}

	const listExpensesDialog = () => {
		return(
			<Dialog header={dialogValues.titleListExpenses} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openListExpenses:false})} className="responsive-dialog-big">
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
					<DataTable
						value={dataValues.expensesList}
						scrollable
						scrollHeight="54vh"
						style={{"minHeight":"57vh"}}
						scrollDirection="both"
						stripedRows
						resizableColumns
						columnResizeMode="fit"
						responsiveLayout="stack"
					>
						<Column field="date_parsed" header="Date" style={{"flexGrow":"0","flexBasis":"120px"}} body={tableColTemplateExpenses} sortable></Column>
						<Column field="uploaded_by" header="Uploaded by" style={{"flexGrow":"1","flexBasis":"30px"}} body={tableColTemplateExpenses} sortable></Column>
						<Column field="value" header="Value" style={{"flexGrow":"0","flexBasis":"100px"}} body={tableColTemplateExpenses} sortable></Column>
						<Column field="filename" header="Uploaded expense scan" style={{"flexGrow":"1","flexBasis":"380px"}} body={tableColTemplateExpenses} sortable></Column>
						<Column field="buttons" header="Operations" style={{"flexGrow":"0","flexBasis":"312px"}} body={tableColTemplateExpenses}></Column>
					</DataTable>
				</Box>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
					<DataTable
						value={dataValues.expensesList}
						responsiveLayout="stack"
					>
						<Column field="date_parsed" header="Date" style={{}}></Column>
						<Column field="uploaded_by" header="Uploaded by" style={{}}></Column>
						<Column field="value" header="Value" style={{}}></Column>
						<Column field="filename" header="Uploaded expense scan" style={{}}></Column>
						<Column field="buttons" header="Operations" style={{}} body={tableColTemplateExpensesSmall}></Column>
					</DataTable>
				</Box>
			</Dialog>
		);
	}

	const setExpenseValueDialog = () => {
		return(
			<Dialog header={dialogValues.titleSetExpenseValue} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openSetExpenseValue:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{}}>
					<Grid item xs={6} sm={6} md={6} lg={7} xl={8} className="field padding-med">
						<label htmlFor="expense_value">Expense value</label>
						<InputText name="expense_value" id="expense_value" pattern="[0-9.]*" inputmode="numeric" style={{"width":"100%"}} value={inputValues.expense_value} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={5} xl={4} className="field padding-med text-right">
						<Button label="Save value" icon="pi pi-check" className="p-button-sm p-button-raised" style={{"marginTop":"24px"}} onClick={(evt) => handleButton(evt,"save_expense_value")}/>
					</Grid>															
				</Grid>
			</Dialog>
		);
	}

	const inventoryDialog = () => {
		return(
			<Dialog header={dialogValues.titleInventory} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openInventory:false})} className="responsive-dialog-big" style={{"minHeight":"714px"}}>
				<InventoryOperations session={props.session} expenseId={currentExpense.id} customerId={props.customer.id}/>
			</Dialog>
		);
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{}}>
					{!!(customDates) &&
						<React.Fragment>
							{!!(dataValues.dashboardExpensesList.length>0) &&
								<React.Fragment>
									<Grid item xs={12} sm={12} md={3} lg={4} xl={3} className="field padding-med">
										<label htmlFor="filter_name_expenses">Filter records</label>
										<InputText name="filter_name_expenses" id="filter_name_expenses" style={{"width":"100%"}} placeholder="Filter by customer..." value={inputValues.filter_name_expenses} onChange={(evt) => handleChange(evt)}/>
									</Grid>
								</React.Fragment>
							}
							{!!(dataValues.dashboardExpensesList.length===0) &&
								<React.Fragment>
									<Grid item xs={12} sm={12} md={3} lg={4} xl={3} className="field padding-med">
										<label htmlFor="filter_name_expenses">Filter records</label>
										<InputText name="filter_name_expenses" id="filter_name_expenses" style={{"width":"100%"}} placeholder="Filter by customer..." value={inputValues.filter_name_expenses} onChange={(evt) => handleChange(evt)} disabled={true}/>
									</Grid>
								</React.Fragment>
							}
							<Grid item xs={12} sm={12} md={1} lg={2} xl={3} className="field padding-med">
								&nbsp;
							</Grid>
							<Grid item xs={6} sm={6} md={2} lg={1} xl={1} className="field padding-med">
								<label htmlFor="">Start date</label>
								<Calendar value={inputValues.start_date} onChange={(evt) => setInputValues({...inputValues,start_date: evt.value})}/>
							</Grid>
							<Grid item xs={6} sm={6} md={2} lg={1} xl={1} className="field padding-med">
							<label htmlFor="">Stop date</label>
								<Calendar value={inputValues.stop_date} onChange={(evt) => setInputValues({...inputValues,stop_date: evt.value})}/>
							</Grid>
							<Grid item xs={12} sm={12} md={1} lg={1} xl={1} className="field padding-med text-center">
								<Button label="Update" icon="pi pi-refresh" className="p-button-sm p-button-raised" style={{"marginTop":"22px"}} onClick={(evt) => handleButton(evt,"update")}/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3} xl={3} className="field padding-med">
								<label htmlFor="period">Period</label>
								<Dropdown name="period" id="period" style={{"width":"100%"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>
							</Grid>
						</React.Fragment>
					}
					{!!((!customDates)&&(dataValues.dashboardExpensesList.length>0)) &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={7} lg={4} xl={3} className="field padding-med text-left">
								<label htmlFor="filter_name_expenses">Filter records</label>
								<InputText name="filter_name_expenses" id="filter_name_expenses" placeholder="Filter by customer..." style={{"width":"100%"}} value={inputValues.filter_name_expenses} onChange={(evt) => handleChange(evt)}/>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={4} xl={5} className="field padding-med">
								&nbsp;
							</Grid>
							<Grid item xs={12} sm={12} md={1} lg={1} xl={1} className="field padding-med text-center">
								<Button label="Update" icon="pi pi-refresh" className="p-button-sm p-button-raised" style={{"marginTop":"22px"}} onClick={(evt) => handleButton(evt,"update")}/>
							</Grid>
							<Grid item xs={12} sm={12} md={5} lg={3} xl={3} className="field padding-med">
								<label htmlFor="period">Period</label>
								<Dropdown name="period" id="period" style={{"width":"100%"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>
							</Grid>
						</React.Fragment>
					}
					{!!((!customDates)&&(dataValues.dashboardExpensesList.length===0)) &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={7} lg={4} xl={3} className="field padding-med text-left">
								<label htmlFor="filter_name_expenses">Filter records</label>
								<InputText name="filter_name_expenses" id="filter_name_expenses" style={{"width":"100%"}} placeholder="Filter by customer..." value={inputValues.filter_name_expenses} onChange={(evt) => handleChange(evt)} disabled={true}/>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={4} xl={5} className="field padding-med">
								&nbsp;
							</Grid>
							<Grid item xs={12} sm={12} md={1} lg={1} xl={1} className="field padding-med text-center">
								<Button label="Update" icon="pi pi-refresh" className="p-button-sm p-button-raised" style={{"marginTop":"22px"}} onClick={(evt) => handleButton(evt,"update")}/>
							</Grid>
							<Grid item xs={12} sm={12} md={5} lg={3} xl={3} className="field padding-med">
								<label htmlFor="period">Period</label>
								<Dropdown name="period" id="period" style={{"width":"100%"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>
							</Grid>
						</React.Fragment>
					}
				</Grid>
				<React.Fragment>
					<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
						<div className="card">
							<div>
								<h5 style={{"float":"left"}}>Expenses list</h5>
								{!!(selectedItems.length>0) &&
									<div style={{"float":"right","marginRight":"1.5%"}}>
										<Button label="Export selection as XLS" icon="pi pi-file-excel" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"export_selection_xls")}/>
									</div>
								}
							</div>
							<br style={{"clear":"both"}}/>
							<DataTable
								value={dashboardExpensesList}
								scrollable
								scrollHeight="60vh"
								style={{"minHeight":"63vh"}}
								scrollDirection="both"
								stripedRows
								resizableColumns
								columnResizeMode="fit"
								selectionMode={rowClick?null:"checkbox"}
								selection={selectedItems} onSelectionChange={(evt) => setSelectedItems(evt.value)}
								responsiveLayout="stack"
							>
								<Column selectionMode="multiple" headerStyle={{"width":"30px","padding":"7px"}}></Column>
								<Column field="name" header="Name" style={{"flexGrow":"1","flexBasis":"180px"}} body={tableColTemplateGeneralExpenses} sortable></Column>
								<Column field="expenses_no" header="Expenses no." style={{"flexGrow":"1","flexBasis":"30px"}} body={tableColTemplateGeneralExpenses} sortable></Column>
								<Column field="expenses_value" header="Expenses value" style={{"flexGrow":"1","flexBasis":"45px"}} body={tableColTemplateGeneralExpenses} sortable></Column>
								<Column field="currency" header="Currency" style={{"flexGrow":"1","flexBasis":"20px"}} body={tableColTemplateGeneralExpenses} sortable></Column>
								<Column field="buttons" header="Operations" style={{"flexGrow":"1","flexBasis":"75px"}} body={tableColTemplateGeneralExpenses}></Column>
							</DataTable>
						</div>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
						<div className="card">
							<h5>Expenses list</h5>
							<DataTable
								value={dashboardExpensesList}
								responsiveLayout="stack"
							>
								<Column field="name" header="Name" style={{}} sortable></Column>
								<Column field="expenses_no" header="Expenses no." style={{}} sortable></Column>
								<Column field="expenses_value" header="Expenses value" style={{}}></Column>
								<Column field="currency" header="Currency" style={{}} sortable></Column>
								<Column field="buttons" header="Operations" style={{}} body={tableColTemplateGeneralExpensesSmall}></Column>
							</DataTable>
						</div>
					</Box>
				</React.Fragment>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openListExpenses && listExpensesDialog()}
				{dialogValues.openSetExpenseValue && setExpenseValueDialog()}
				{dialogValues.openInventory && inventoryDialog()}
			</BlockUI>
		);			
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default Expenses;
