import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Box} from "@mui/system";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {ConfirmDialog} from "primereact/confirmdialog";
import {confirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import {fetch_url_post,get_monday,format_date,fetch_download} from "../../Sources/js/app.js";
import {get_api_path} from "../../Sources/js/paths.js";

let oper="";
let row_ct=0;

let defaultInputValues={
	period: "today",
	start_date: new Date(),
	stop_date: new Date(),
	filter_name_invoices: "",
};

let defaultDataValues={
	dashboardList: [],
	invoicesList: [],
	expensesList: [],
	paymentsList: [],
};

let defaultDialogValues={
	openListInvoices: false,
	titleListInvoices: "",
	openViewInvoice: false,
	titleViewInvoice: "",
	openDeletionDetails: false,
	titleDeletionDetails: "",	
	openPayments: false,
	titlePayments: "",
	openAddPayments: false,
	titleAddPayments: "",
};

let defaultInputValuesPayments={
	id: null,
	amount: "",
	payment_type: "",
	payment_date: new Date(),
	details: "",
};

let periodsList=[
	{label: "Today",value: "today"},
//	{label: "Yesterday",value: "yesterday"},
	{label: "Current week",value: "c_week"},
//	{label: "Pervious week",value: "p_week"},	
	{label: "Current month",value: "c_month"},
//	{label: "Previous month",value: "p_month"},
	{label: "Custom interval",value: "custom"},
]

let paymentsTypesList=[
	{label: "CASH",value: "CASH"},
	{label: "BANK",value: "BANK"},
	{label: "PAYMENT PROCESSOR",value: "PAYMENT PROCESSOR"},
	{label: "OTHER",value: "OTHER"},
]

let currentInvoice={};

const Invoices = (props) => {

	var timerId=null;
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [customDates, setCustomDates] = useState(false);
	const [dashboardInvoicesList, setDashboardInvoicesList] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
    const [rowClick, setRowClick] = useState(true);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [pdfUrl, setPdfUrl] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
	const [inputValuesPayments, setInputValuesPayments] = useState(defaultInputValuesPayments);
	const [inputErrorPayments, setInputErrorPayments] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

	const handleButton = (evt,what,elementData) => {
		if(what==="update") {
			let data={};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				if((inputValues.start_date)&&(inputValues.stop_date)) {
					data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
					data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
				}else{
					setMessageText("Error: Select time interval!");				
				}
			}
			data.customer_id=props.customer.id;
			oper="get";
			let url="initGeneralDashboardInvoices";
			url+="?session_id="+props.session.sessId;
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="list_invoices") {
			oper="get_invoices_list";
			let url="getInvoicesList";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
				currency: elementData.currency,
			};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="view_invoice") {
			setPdfUrl(get_api_path()+"getPdfInvoice"+"?session_id="+props.session.sessId+"&hash="+elementData.invoice_hash);
			setDialogValues({...dialogValues,openViewInvoice: true,titleViewInvoice: "Invoice #"+elementData.number});
		}
		if(what==="download_invoice") {
			let url="getPdfInvoice";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
			};
			fetch_download(url,data,"Invoice_"+elementData.number+".pdf",success_handler);
		}
		if(what==="export_xls") {
			let url="getXLSInvoicesList";
			oper="download";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
				currency: elementData.currency,
				customer_name: elementData.name,
			};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_download(url,data,"invoices_report",success_handler);
			setBlockScreen(true);
			setMessageText("Downloading file, please wait...");
		}
		if(what==="export_selection_xls") {
			let url="getXLSInvoicesListSummary";
			oper="download";
			url+="?session_id="+props.session.sessId;
			let data={
				invoices_list: selectedItems,
			};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_download(url,data,"invoices_report",success_handler);
			setBlockScreen(true);
			setMessageText("Downloading file, please wait...");
		}
		if(what==="delete_invoice") {
			let url="deleteInvoice";
			oper="delete_invoice";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
				currency: elementData.currency,
				id: elementData.id,
			};
			let d=new Date();
			if(inputValues.period==="today") {
				data.start_date=format_date(d,"yyyy-mm-dd");
			}
			if(inputValues.period==="yesterday") {
				data.start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_week") {
				data.start_date=format_date(get_monday(d),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_week") {
				data.start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="c_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
			}
			if(inputValues.period==="p_month") {
				data.start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
			}
			if(inputValues.period==="custom") {
				data.start_date=format_date(inputValues.start_date,"yyyy-mm-dd");
				data.stop_date=format_date(inputValues.stop_date,"yyyy-mm-dd");
			}
			fetch_url_post(url,data,success_handler,fail_handler);				
		}
		if(what==="deletion_details") {
			let url="invoiceDeletionDetails";
			oper="deletion_details";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);		
		}
		if(what==="payments") {
			currentInvoice={...elementData};
			oper="get_payments";
			let url="getPayments";
			url+="?session_id="+props.session.sessId;
			let data={
				invoice_id: elementData.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);	
		}
		if(what==="add_payment") {
			setInputValuesPayments({...defaultInputValuesPayments,amount:currentInvoice.rest});
			setInputErrorPayments("");
			setDialogValues({...dialogValues,openAddPayments: true,titleAddPayments: "Add new payment"});
		}
		if(what==="edit_payment") {
			setInputErrorPayments("");
			let tmp={...elementData};
			tmp.payment_date=new Date(tmp.payment_date);
			setInputValuesPayments(tmp);
			setDialogValues({...dialogValues,openAddPayments: true,titleAddPayments: "Edit payment"});
		}
		if(what==="save_payment") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			setInputErrorPayments("");
			if((inputValuesPayments.amount==="")||(inputValuesPayments.amount==="0")) {
				setInputErrorPayments("Please fill amount!");				
				document.getElementById("amount").classList.add("required_input_error");
				return false;
			}
			if(!inputValuesPayments.payment_type) {
				setInputErrorPayments("Please select type!");				
				document.getElementById("payment_type").classList.add("required_input_error");
				return false;
			}
			let url="savePayment";
			url+="?session_id="+props.session.sessId;
			oper="save_payment";
			let data={...inputValuesPayments};
			data.payment_date=format_date(data.payment_date,"yyyy-mm-dd");
			data.invoice_id=currentInvoice.id;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete_payment") {
			oper="delete_payment";
			let url="deletePayment";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);	
		}
	}

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="period") {
			if(value) {
				//form date
				let start_date="";
				let d=new Date();
				setCustomDates(false);
				if(value==="today") {
					start_date=format_date(d,"yyyy-mm-dd");
				}
				if(value==="yesterday") {
					start_date=format_date(new Date(d.setDate(d.getDate()-1)),"yyyy-mm-dd");
				}
				if(value==="c_week") {
					start_date=format_date(get_monday(d),"yyyy-mm-dd");
				}
				if(value==="p_week") {
					start_date=format_date(get_monday(d,1),"yyyy-mm-dd");
				}
				if(value==="c_month") {
					start_date=format_date(new Date(d.getFullYear(),d.getMonth(),1),"yyyy-mm-dd");
				}
				if(value==="p_month") {
					start_date=format_date(new Date(d.getFullYear(),d.getMonth()-1,1),"yyyy-mm-dd");
				}
				if(value==="custom") {
					setCustomDates(true);
				}
				if(value!=="custom") {
					oper="get";
					let url="initGeneralDashboardInvoices";
					url+="?session_id="+props.session.sessId;
					let data={
						customer_id: props.customer.id,
						start_date: start_date,
					};
					fetch_url_post(url,data,success_handler,fail_handler);				
				}
			}
		}
		if(name==="filter_name_invoices") {
			setDashboardInvoicesList(dataValues.dashboardInvoicesList.filter((item)=>((item.name.toLowerCase().indexOf(value.toLowerCase())>-1))||(value==="")));
		}		
		setInputValues({...inputValues,[name]:value});
	}

	const handleChangePayments = evt => {
		const {name, value} = evt.target;
		setInputErrorPayments("");							
		if(name==="amount") {
			if(!evt.target.validity.valid) {
				setInputErrorPayments("Only numbers are allowed!");							
				return false;
			}
			if(currentInvoice.partial_payments==0) {
				setInputErrorPayments("Partial payments not allowed!");							
				return false;				
			}
		}
		setInputValuesPayments({...inputValuesPayments,[name]:value});
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="init")||(oper==="get")) {
			setDataValues({...dataValues,dashboardInvoicesList:(result.dashboardInvoicesList?result.dashboardInvoicesList:[])});
			setDashboardInvoicesList(result.dashboardInvoicesList?result.dashboardInvoicesList.filter((item)=>((item.name.toLowerCase().indexOf(inputValues.filter_name_invoices.toLowerCase())>-1))||(inputValues.filter_name_invoices==="")):[]);
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if((oper==="get_invoices_list")||(oper==="delete_invoice")) {
			setDataValues({...dataValues,invoicesList:(result.invoicesList?result.invoicesList:[])});
			if(oper==="get_invoices_list") {
				setDialogValues({...dialogValues,openListInvoices: true,titleListInvoices: "Invoices list"});
			}
			oper="";
		}
		if(oper==="download") {
			setMessageText(null);
			setBlockScreen(false);
			oper="";
		}
		if(oper==="deletion_details") {
			setDialogValues({...dialogValues,openDeletionDetails: true,titleDeletionDetails: "Deletion details for invoice"});
			oper="";
		}
		if((oper==="get_payments")||(oper==="save_payment")||(oper==="delete_payment")) {
			if(oper==="get_payments") {
				setDataValues({...dataValues,
											paymentsList:(result.paymentsList?result.paymentsList:[]),
											partial_payments: result.partial_payments,
											invoice_total: result.total,
				});
				setDialogValues({...dialogValues,openPayments: true,titlePayments: "Payments for invoice "+currentInvoice.number+"/"+currentInvoice.date_parsed});
			}else{
				//we must modify invoices list (dataValues.invoicesList)
				const updatedInvoicesList=dataValues.invoicesList.map(invoice => {
																			if(invoice.id===currentInvoice.id) {
																				let tmp=invoice;
																				tmp.payed_amount=result.paymentsList.reduce((accumulator,currentValue) => {
																					const numericValue=parseFloat(currentValue.amount);
																					return accumulator+numericValue;
																				},0);
																				return {...invoice, ...tmp};
																			}
																			return invoice;
				});
				setDataValues({...dataValues,paymentsList:(result.paymentsList?result.paymentsList:[]),
											invoicesList:updatedInvoicesList,
				});
				setDialogValues({...dialogValues,openAddPayments: false,titleAddPayments: ""});				
			}
			//update amount
			let tmp=result.paymentsList.reduce((accumulator,currentValue) => {
				const numericValue=parseFloat(currentValue.amount);
				return accumulator+numericValue;
			},0);
			tmp=parseFloat(currentInvoice.total)-tmp;
			currentInvoice.rest=tmp;
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init";
			let url="initGeneralDashboardInvoices";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
				start_date: format_date(new Date(),"yyyy-mm-dd"),
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[]);

	const tableColTemplateGeneralInvoices = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"6px"}}>{row_data[column.field]}</span>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="View invoices" icon="pi pi-list" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={(row_data.invoices_no>0?false:true)} onClick={(evt) => handleButton(evt,"list_invoices",row_data)}/>
					<Button label="Export as XLS" icon="pi pi-file-excel" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"export_xls",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateGeneralInvoicesSmall = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"6px"}}>{row_data[column.field]}</span>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button tooltip="View invoices" icon="pi pi-list" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={(row_data.invoices_no>0?false:true)} onClick={(evt) => handleButton(evt,"list_invoices",row_data)}/>
					<Button tooltip="Export as XLS" icon="pi pi-file-excel" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"export_xls",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateInvoices = (row_data,column) => {
		if(column.field!=="buttons") {
			if(row_data.status==="DELETED") {
				if((column.field==="currency")||(column.field==="status")||(column.field==="sended")) {
					return(
						<span style={{"paddingLeft":"12px","textDecoration":"line-through"}}>{row_data[column.field]}</span>
					)
				}else{
					if(column.field==="payed_amount") {
						return(
							<span style={{"paddingLeft":"30px","textDecoration":"line-through"}}>{row_data[column.field]}</span>
						)
					}
					return(
						<span style={{"paddingLeft":"1px","textDecoration":"line-through"}}>{row_data[column.field]}</span>
					);
				}
			}else{
				if((column.field==="currency")||(column.field==="status")||(column.field==="sended")) {
					return(
						<span style={{"paddingLeft":"12px"}}>{row_data[column.field]}</span>
					)
				}else{
					if(column.field==="payed_amount") {
						return(
							<span style={{"paddingLeft":"36px"}}>{row_data[column.field]}</span>
						)
					}
					return(
						<span style={{"paddingLeft":"1px"}}>{row_data[column.field]}</span>
					);
				}
			}
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="View" icon="pi pi-list" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"view_invoice",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateInvoicesSmall = (row_data,column) => {
		if(column.field!=="buttons") {
			if(row_data.status==="DELETED") {
				return(
					<span style={{"paddingLeft":"1px","textDecoration":"line-through"}}>{row_data[column.field]}</span>
				);
			}else{
				return(
					<span style={{"paddingLeft":"1px"}}>{row_data[column.field]}</span>
				);
			}
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button tooltip="View invoice" icon="pi pi-list" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"view_invoice",row_data)}/>
					<Button tooltip="Download invoice" icon="pi pi-download" className="p-button-sm p-button-raised" style={{}} onClick={(evt) => handleButton(evt,"download_invoice",row_data)}/>
				</span>
			);
		}
	}

	const confirm = (evt,what,elementData) => {
		if(what==="request_deletion") {
				confirmDialog({
						message: "Are you sure you want to request deletion?",
						header: "Confirm delete request",
						icon: "pi pi-exclamation-triangle",
						accept: () => handleButton(evt,what,elementData),
						reject: () => null,
				});
		}
		if(what==="cancel_request_deletion") {
			confirmDialog({
					message: "Are you sure you want to cancel deletion request?",
					header: "Confirm cancel deletion request",
					icon: "pi pi-exclamation-triangle",
					accept: () => handleButton(evt,what,elementData),
					reject: () => null,
			});
		}
		if(what==="delete_invoice") {
			confirmDialog({
					message: "Are you sure you want to DELETE?",
					header: "Confirm DELETE",
					icon: "pi pi-exclamation-triangle",
					accept: () => handleButton(evt,what,elementData),
					reject: () => null,
			});
		}
		if(what==="delete_payment") {
			confirmDialog({
					message: "Are you sure you want to delete payment?",
					header: "Confirm delete request",
					icon: "pi pi-exclamation-triangle",
					accept: () => handleButton(evt,what,elementData),
					reject: () => null,
			});
		}
	}

    const rowClass = (row_data) => {
		row_ct++;
		return {
            "invoices_rfd_row": row_data.status==="DELETE_REQUESTED",
            "invoices_deleted_row": row_data.status==="DELETED",
			"p-row-odd": row_ct%4===0
        };
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div>
				<div style={{"paddingLeft":"3rem"}}>
					<Button label="View invoice" icon="pi pi-list" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"view_invoice",data)}/>
					<Button label="Download" icon="pi pi-download" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"download_invoice",data)}/>                                                  
					{!!((data.status==="DELETE_REQUESTED")&&(props.session.delete_invoices===1)) &&
						<React.Fragment>
							<Button label="Delete invoice" icon="pi pi-trash" className="p-button-sm p-button-danger" style={{"marginRight":"6px"}} onClick={(evt) => confirm(evt,"delete_invoice",data)}/>                                                  
						</React.Fragment>
					}
					{!!(data.status==="DELETED") &&
						<React.Fragment>
							<Button label="Deletion details" icon="pi pi-eye" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"deletion_details",data)}/>                                                  
						</React.Fragment>
					}
					<Button label="Payments" icon="pi pi-dollar" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"payments",data)}/>                                                  
				</div>
            </div>
        );
    };

	const listInvoicesDialog = () => {
		return(
			<Dialog header={dialogValues.titleListInvoices} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openListInvoices:false})} className="responsive-dialog-big">
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
					<DataTable
						value={dataValues.invoicesList}
						scrollable
						scrollHeight="54vh"
						style={{"minHeight":"57vh"}}
						scrollDirection="both"
						stripedRows
						resizableColumns
						columnResizeMode="fit"
						responsiveLayout="stack"
						expandedRows={expandedRows}
						onRowToggle={(evt) => setExpandedRows(evt.data)}
						rowExpansionTemplate={rowExpansionTemplate}
						rowClassName={rowClass}
					>
						<Column expander={true} style={{"width":"3rem"}}></Column>
						<Column field="number" header="No." style={{"flexGrow":"1","flexBasis":"20px"}} sortable></Column>
						<Column field="date_parsed" header="Date" style={{"flexGrow":"1","flexBasis":"30px"}} body={tableColTemplateInvoices} sortable></Column>
						<Column field="client_name" header="Client name" style={{"flexGrow":"1","flexBasis":"230px"}} body={tableColTemplateInvoices} sortable></Column>
						<Column field="status" header="Status" style={{"flexGrow":"1","flexBasis":"70px"}} body={tableColTemplateInvoices} sortable></Column>
						<Column field="subtotal" header="Subtotal" style={{"flexGrow":"1","flexBasis":"30px"}} body={tableColTemplateInvoices} sortable></Column>
						<Column field="vat" header="VAT" style={{"flexGrow":"1","flexBasis":"30px"}} body={tableColTemplateInvoices} sortable></Column>
						<Column field="total" header="Total" style={{"flexGrow":"1","flexBasis":"30px"}} body={tableColTemplateInvoices} sortable></Column>
						<Column field="sended" header="Sent" style={{"flexGrow":"1","flexBasis":"10px"}} body={tableColTemplateInvoices} sortable></Column>
					</DataTable>
				</Box>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
					<DataTable
						value={dataValues.invoicesList}
						responsiveLayout="stack"
					>
						<Column field="number" header="#" style={{}}></Column>
						<Column field="date_parsed" header="Date" style={{}}></Column>
						<Column field="client_name" header="Client name" style={{}}></Column>
						<Column field="status" header="Status" style={{}}></Column>
						<Column field="subtotal" header="Subtotal" style={{}}></Column>
						<Column field="vat" header="VAT" style={{}}></Column>
						<Column field="total" header="Total" style={{}}></Column>
						<Column field="sended" header="Sent" style={{}}></Column>
						<Column field="buttons" header="Operations" style={{}} body={tableColTemplateInvoicesSmall}></Column>
					</DataTable>
				</Box>
			</Dialog>
		)
	}

	const viewInvoiceDialog = () => {
		if(pdfUrl) {
			return(
				<Dialog header={dialogValues.titleViewInvoice} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openViewInvoice:false})} className="responsive-dialog-big">
					<iframe src={`${pdfUrl}#view=fitH`} title="PDF" width="100%" height="501px"></iframe>
				</Dialog>
			);
		}
	}

	const deletionDetailsDialog = () => {
		return(
			<Dialog header={dialogValues.titleDeletionDetails} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDeletionDetails:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{}}>

				</Grid>
			</Dialog>
		);
	}

	const paymentsDialog = () => {
		return(
			<Dialog header={dialogValues.titlePayments} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openPayments:false})} position="right" className="responsive-dialog-med">
				<Grid container spacing={1} style={{"marginLeft":"0px","width":"99.99%"}}>
					{!!((currentInvoice.partial_payments===1)||(dataValues.paymentsList.length===0)) &&
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="field padding-med text-right">
							<Button label="Add payment" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" tooltip="Add payment" style={{}} onClick={(evt) => {handleButton(evt,"add_payment")}}/>
						</Grid>						
					}
					{!!((currentInvoice.partial_payments===0)&&(dataValues.paymentsList.length>0)) &&
						<React.Fragment>
							<Grid item xs={9} sm={9} md={9} lg={9} xl={9} className="field padding-med text-right">
								<p style={{"fontWeight":"600","color":"#FF0000","textAlign":"left","marginTop":"6px"}}>Partial payments not allowed for this invoice.</p>
							</Grid>						
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="field padding-med text-right">
								<Button label="Add payment" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" tooltip="Add payment" style={{}} disabled={true}/>
							</Grid>						
						</React.Fragment>
					}
				</Grid>
				<DataTable
					value={dataValues.paymentsList}
					scrollable
					scrollHeight="30vh"
					style={{"minHeight":"30vh","marginBottom":"18px"}}
					stripedRows
					resizableColumns
					columnResizeMode="fit"
					emptyMessage={<p style={{"textAlign":"center"}}>No entries found...</p>}
				>
					<Column field="amount" header="Amount" style={{"flexGrow":"0","flexBasis":"130px"}} body={tableColTemplatePayments}></Column>
					<Column field="payment_type" header="Payment type" style={{"flexGrow":"0","flexBasis":"190px"}} body={tableColTemplatePayments}></Column>
					<Column field="payment_date" header="Date" style={{"flexGrow":"0","flexBasis":"120px"}} body={tableColTemplatePayments}></Column>
					<Column field="reg_by_mfa" header="Registered by" style={{"flexGrow":"1","flexBasis":"150px"}} body={tableColTemplatePayments}></Column>
					<Column field="buttons" header="" style={{"flexGrow":"0","flexBasis":"160px"}} body={tableColTemplatePayments}></Column>
				</DataTable>
			</Dialog>
		);
	}

	const addPaymentsDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddPayments} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddPayments:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"marginLeft":"0px","width":"99.99%"}}>
					<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
						<label htmlFor="amount">Amount*</label>
						<InputText name="amount" id="amount" style={{"width":"100%"}} pattern="[0-9.]*" value={inputValuesPayments.amount} onChange={(evt) => handleChangePayments(evt)}/>
					</Grid>					
					<Grid item xs={12} sm={12} md={6} lg={6} className="field padding-med">
						<label htmlFor="">Type*</label>
						<Dropdown name="payment_type" id="payment_type" style={{"width":"100%"}} options={paymentsTypesList} value={inputValuesPayments.payment_type} onChange={(evt) => handleChangePayments(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
						<label htmlFor="payment_date">Payment date*</label>
						<Calendar value={inputValuesPayments.payment_date} style={{"width":"100%"}} onChange={(evt) => setInputValuesPayments({...inputValuesPayments,payment_date: evt.value})}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="details">Payment details</label>
						<InputText name="details" id="details" style={{"width":"100%"}} value={inputValuesPayments.details} onChange={(evt) => handleChangePayments(evt)}/>
					</Grid>					
					<Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="field padding-med text-right">
						<p style={{"color":"#FF0000","fontWeight":"600","textAlign":"left"}}>{inputErrorPayments}</p>
					</Grid>						
					<Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="field padding-med text-right">
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} disabled={((inputErrorPayments==="")||(inputErrorPayments==="Partial payments not allowed!"))?false:true} onClick={(evt) => handleButton(evt,"save_payment")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddPayments:false})}/>
					</Grid>						
				</Grid>
			</Dialog>
		);
	}

	const tableColTemplatePayments = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<span style={{"paddingLeft":"1px"}}>{row_data[column.field]}</span>
			)
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button label="Edit" icon="pi pi-pencil" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"edit_payment",row_data)}/>
					<Button label="Delete" icon="pi pi-trash" className="p-button-sm p-button-raised p-button-danger" style={{}} onClick={(evt) => confirm(evt,"delete_payment",row_data)}/>
				</span>
			);
		}
	}	

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{}}>
					{!!(customDates) &&
						<React.Fragment>
							{!!(dataValues.dashboardInvoicesList.length>0) &&
								<React.Fragment>
									<Grid item xs={12} sm={12} md={3} lg={4} xl={3} className="field padding-med">
										<label htmlFor="filter_name_invoices">Filter records</label>
										<InputText name="filter_name_invoices" id="filter_name_invoices" style={{"width":"100%"}} placeholder="Filter by customer..." value={inputValues.filter_name_invoices} onChange={(evt) => handleChange(evt)}/>
									</Grid>
								</React.Fragment>
							}
							{!!(dataValues.dashboardInvoicesList.length===0) &&
								<React.Fragment>
									<Grid item xs={12} sm={12} md={3} lg={4} xl={3} className="field padding-med">
										<label htmlFor="filter_name_invoices">Filter records</label>
										<InputText name="filter_name_invoices" id="filter_name_invoices" style={{"width":"100%"}} placeholder="Filter by customer..." value={inputValues.filter_name_invoices} onChange={(evt) => handleChange(evt)} disabled={true}/>
									</Grid>
								</React.Fragment>
							}
							<Grid item xs={12} sm={12} md={1} lg={2} xl={3} className="field padding-med">
								&nbsp;
							</Grid>
							<Grid item xs={6} sm={6} md={2} lg={1} xl={1} className="field padding-med">
								<label htmlFor="">Start date</label>
								<Calendar value={inputValues.start_date} onChange={(evt) => setInputValues({...inputValues,start_date: evt.value})}/>
							</Grid>
							<Grid item xs={6} sm={6} md={2} lg={1} xl={1} className="field padding-med">
							<label htmlFor="">Stop date</label>
								<Calendar value={inputValues.stop_date} onChange={(evt) => setInputValues({...inputValues,stop_date: evt.value})}/>
							</Grid>
							<Grid item xs={12} sm={12} md={1} lg={1} xl={1} className="field padding-med text-center">
								<Button label="Update" icon="pi pi-refresh" className="p-button-sm p-button-raised" style={{"marginTop":"22px"}} onClick={(evt) => handleButton(evt,"update")}/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3} xl={3} className="field padding-med">
								<label htmlFor="period">Period</label>
								<Dropdown name="period" id="period" style={{"width":"100%"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>
							</Grid>
						</React.Fragment>
					}
					{!!((!customDates)&&(dataValues.dashboardInvoicesList.length>0)) &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={7} lg={4} xl={3} className="field padding-med text-left">
								<label htmlFor="filter_name_invoices">Filter records</label>
								<InputText name="filter_name_invoices" id="filter_name_invoices" placeholder="Filter by customer..." style={{"width":"100%"}} value={inputValues.filter_name_invoices} onChange={(evt) => handleChange(evt)}/>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={4} xl={5} className="field padding-med">
								&nbsp;
							</Grid>
							<Grid item xs={12} sm={12} md={1} lg={1} xl={1} className="field padding-med text-center">
								<Button label="Update" icon="pi pi-refresh" className="p-button-sm p-button-raised" style={{"marginTop":"22px"}} onClick={(evt) => handleButton(evt,"update")}/>
							</Grid>
							<Grid item xs={12} sm={12} md={5} lg={3} xl={3} className="field padding-med">
								<label htmlFor="period">Period</label>
								<Dropdown name="period" id="period" style={{"width":"100%"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>
							</Grid>
						</React.Fragment>
					}
					{!!((!customDates)&&(dataValues.dashboardInvoicesList.length===0)) &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={7} lg={4} xl={3} className="field padding-med text-left">
								<label htmlFor="filter_name_invoices">Filter records</label>
								<InputText name="filter_name_invoices" id="filter_name_invoices" style={{"width":"100%"}} placeholder="Filter by customer..." value={inputValues.filter_name_invoices} onChange={(evt) => handleChange(evt)} disabled={true}/>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={4} xl={5} className="field padding-med">
								&nbsp;
							</Grid>
							<Grid item xs={12} sm={12} md={1} lg={1} xl={1} className="field padding-med text-center">
								<Button label="Update" icon="pi pi-refresh" className="p-button-sm p-button-raised" style={{"marginTop":"22px"}} onClick={(evt) => handleButton(evt,"update")}/>
							</Grid>
							<Grid item xs={12} sm={12} md={5} lg={3} xl={3} className="field padding-med">
								<label htmlFor="period">Period</label>
								<Dropdown name="period" id="period" style={{"width":"100%"}} options={periodsList} value={inputValues.period} onChange={(evt) => handleChange(evt)}/>
							</Grid>
						</React.Fragment>
					}
				</Grid>
				<React.Fragment>
					<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
						<div className="card">
							<div>
								<h5 style={{"float":"left"}}>Invoices list</h5>
								{!!(selectedItems.length>0) &&
									<div style={{"float":"right","marginRight":"1.5%"}}>
										<Button label="Export selection as XLS" icon="pi pi-file-excel" className="p-button-sm p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"export_selection_xls")}/>
									</div>
								}
							</div>
							<br style={{"clear":"both"}}/>
							<DataTable
								value={dashboardInvoicesList}
								scrollable
								scrollHeight="60vh"
								style={{"minHeight":"63vh"}}
								scrollDirection="both"
								stripedRows
								resizableColumns
								columnResizeMode="fit"
								selectionMode={rowClick?null:"checkbox"}
								selection={selectedItems} onSelectionChange={(evt) => setSelectedItems(evt.value)}
								responsiveLayout="stack"
							>
								<Column selectionMode="multiple" headerStyle={{"width":"30px","padding":"7px"}}></Column>
								<Column field="name" header="Name" style={{"flexGrow":"1","flexBasis":"150px"}} body={tableColTemplateGeneralInvoices} sortable></Column>
								<Column field="invoices_no" header="Invoices" style={{"flexGrow":"1","flexBasis":"30px"}} body={tableColTemplateGeneralInvoices} sortable></Column>
								<Column field="subtotal" header="Subtotal" style={{"flexGrow":"1","flexBasis":"45px"}} body={tableColTemplateGeneralInvoices} sortable></Column>
								<Column field="vat" header="VAT" style={{"flexGrow":"1","flexBasis":"20px"}} body={tableColTemplateGeneralInvoices} sortable></Column>
								<Column field="total" header="Total" style={{"flexGrow":"1","flexBasis":"20px"}} body={tableColTemplateGeneralInvoices} sortable></Column>
								<Column field="currency" header="Currency" style={{"flexGrow":"1","flexBasis":"20px"}} body={tableColTemplateGeneralInvoices} sortable></Column>
								<Column field="buttons" header="Operations" style={{"flexGrow":"1","flexBasis":"140px"}} body={tableColTemplateGeneralInvoices}></Column>
							</DataTable>
						</div>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
						<div className="card">
							<h5>Invoices list</h5>
							<DataTable
								value={dashboardInvoicesList}
								responsiveLayout="stack"
							>
								<Column field="name" header="Name" style={{}} sortable></Column>
								<Column field="invoices_no" header="Invoices" style={{}} sortable></Column>
								<Column field="subtotal" header="Subtotal" style={{}}></Column>
								<Column field="vat" header="VAT" style={{}}></Column>
								<Column field="total" header="Total" style={{}}></Column>
								<Column field="currency" header="Currency" style={{}} sortable></Column>
								<Column field="buttons" header="Operations" style={{}} body={tableColTemplateGeneralInvoicesSmall}></Column>
							</DataTable>
						</div>
					</Box>
				</React.Fragment>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openListInvoices && listInvoicesDialog()}
				{dialogValues.openViewInvoice && viewInvoiceDialog()}
				{dialogValues.openDeletionDetails && deletionDetailsDialog()}
				{dialogValues.openPayments && paymentsDialog()}
				{dialogValues.openAddPayments && addPaymentsDialog()}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default Invoices;
