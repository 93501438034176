import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";
import {confirmDialog} from "primereact/confirmdialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import {BlockUI} from "primereact/blockui";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Divider} from "primereact/divider";
import ActivityLog from "./ActivityLog.js";
import Message from "../Helpers/Message.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultInputValues={
	id: null,
	user_name: "",
	password: "",
	confirm_password: "",
	name: "",
	role: "",
	function: "",
	observations: "",
	email: "",
	active: 1,
	allowed_sections: [],
	filter_users: "",
	reg_date_parsed: null,
};

let defaultInputValuesPermissions={
	edit_customers: 0,
	edit_customers_users: 0,
	delete_invoices: 0,
};

let defaultDataValues={
	usersList: [],
	usersMenuList: [],
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
	openChangePassword: false,
	titleChangePassword: "Change password",
	openSections: false,
	titleSections: "",
	openActivity: false,
	titleActivity: "",
};

let userRolesList=[
	{label: "Administrator",value: "admin"},
	{label: "User",value: "user"},
];

let activeList=[
	{label: "Yes",value: 1},
	{label: "No",value: 0},
];

let defaultInputError={
	disableSave: false,
	errorText: "",
};

let currentUser={};

const LocalUsers = (props) => {

	var timerId=null;
	defaultInputValues.id=(props.userId?props.userId:null);

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [inputError, setInputError] = useState(defaultInputError);	
	const [blockScreen, setBlockScreen] = useState(false);
	const [usersList, setUsersList] = useState([]);
	const [inputValuesPermissions, setInputValuesPermissions] = useState(defaultInputValuesPermissions);
    const location = useLocation();
    const navigate = useNavigate();

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="user_name") {
			let found=false;
			if(dataValues.usersList.length>0) {
				dataValues.usersList.forEach(element => {
					if(value.toUpperCase()===element.user_name.toUpperCase()) {
						found=true;
						return;
					}
				});
			}
			let tmp={
				disableSave: found,
				errorText: (found===true?"This username already exists!":""),
			}
			setInputError(tmp);
		}
		if(name==="filter_users") {
			setUsersList(dataValues.usersList.filter((user)=>((user.name.toLowerCase().indexOf(value.toLowerCase())>-1))||(value==="")));
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleChecked = (evt,menu_id) => {
        let allowed_sections = [...inputValues.allowed_sections];
		if(evt.checked) {
            allowed_sections.push(menu_id);
        }else{
            allowed_sections.splice(allowed_sections.indexOf(menu_id),1);
		}
		setInputValues({...inputValues,allowed_sections:allowed_sections});
	}	
	
	const handleCheckedSpecial = (evt) => {
		const {name, checked} = evt.target;
		if(checked) {
			setInputValues({...inputValues,[name]:1});
		}else{
			setInputValues({...inputValues,[name]:0});
		}
	}	

	const handleCheckedSections = (evt) => {
		const {name, checked} = evt.target;
		if(checked) {
			setInputValuesPermissions({...inputValuesPermissions,[name]:1});
		}else{
			setInputValuesPermissions({...inputValuesPermissions,[name]:0});
		}
	}	

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Add new user"});
			setInputError(defaultInputError);
		}
		if(what==="edit") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Edit user"});
			setInputError(defaultInputError);
		}
		if(what==="password") {
			setInputValues({...inputValues,id: elementData.id,password: "",confirm_password: ""});
			setDialogValues({...dialogValues,openChangePassword: true});
		}
		if(what==="activity") {
			currentUser={...elementData};
			setDialogValues({...dialogValues,openActivity: true,titleActivity: "Activity log"});
		}
		if(what==="delete") {
			let url="deleteUser";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.user_name==="") {
				setMessageText("Error: Please fill user name!");				
				document.getElementById("user_name").classList.add("required_input_error")
				return false;
			}
			if(inputValues.name==="") {
				setMessageText("Error: Please fill name!");				
				document.getElementById("name").classList.add("required_input_error")
				return false;
			}
			if(inputValues.role==="") {
				setMessageText("Error: Please select user type!");				
				document.getElementById("role").classList.add("required_input_error")
				return false;
			}
			if(inputValues.email!=="") {
				if((inputValues.email.indexOf("@")===-1)||(inputValues.email.indexOf(".")===-1)) {
					setMessageText("Error: Please fill e-mail address!");				
					document.getElementById("email").classList.add("required_input_error")
					return false;
				}
			}
			if(inputValues.password==="") {
				setMessageText("Error: Please fill password!");				
				document.getElementById("password").classList.add("required_input_error")
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Error: Please fill password confirmation!");				
				document.getElementById("confirm_password").classList.add("required_input_error")
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Error: Password and confirm must be identical!");				
				document.getElementById("password").classList.add("required_input_error");
				document.getElementById("confirm_password").classList.add("required_input_error");
				return false;
			}
			let url="saveUser";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data={...inputValues};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save_password") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.password==="") {
				setMessageText("Error: Please fill password!");				
				document.getElementById("password").classList.add("required_input_error");
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Error: Please fill password confirmation!");				
				document.getElementById("confirm_password").classList.add("required_input_error");
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Error: Password and confirm must be identical!");				
				document.getElementById("password").classList.add("required_input_error");
				document.getElementById("confirm_password").classList.add("required_input_error");
				return false;
			}
			let url="saveUserPassword";
			url+="?session_id="+props.session.sessId;
			oper="save_password";
			let data={...inputValues};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="permissions") {
			setInputValues({...inputValues,elementData});
			currentUser=elementData;
			let url="getUserPermissions";
			url+="?session_id="+props.session.sessId;
			oper="get_user_permissions";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save_sections") {
			let url="saveUserPermissions";
			url+="?session_id="+props.session.sessId;
			oper="save_user_permissions";
			let data={
				id: currentUser.id,
				menu_sections: inputValues.allowed_sections,
				permissions: inputValuesPermissions,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
			currentUser={};
		}
		if(what==="null") {
			navigate("/login");
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save")||(oper==="delete")) {
			if(oper==="save") {
				setDialogValues(defaultDialogValues);
			}
			setDataValues({...dataValues,usersList:(result.usersList?result.usersList:[])});
			setUsersList(result.usersList?result.usersList:[]);
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="save_password") {
			setDialogValues(defaultDialogValues);
			oper="";
		}
		if(oper==="get_user_permissions") {
			setDataValues({...dataValues,usersMenuList:(result.data.menuSections?result.data.menuSections:[])});
			let allowed_sections=[];
			result.data.menuSections.forEach(element=>{
				if(element.allowed===1) {
					allowed_sections.push(element.id);
				}
			});
			currentUser.allowed_sections=allowed_sections;
			setInputValues(currentUser);
			setInputValuesPermissions(result.data.permissions);
			setDialogValues({...dialogValues,openSections: true,titleSections: "User permissions for "+currentUser.user_name});
			oper="";
		}
		if(oper==="save_user_permissions") {
			setInputValues({...inputValues,allowed_sections:[]});			
			setDialogValues({...dialogValues,openSections: false,titleSections: ""});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getUsers";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			navigate("/login");
		}
	},[props.session.sessId]);

	const addEditDialog = () => {
		if(inputValues.id) {
			return(
                <Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} className="responsive-dialog-med">
					<Grid container spacing={1} style={{"paddingTop":"12px"}}>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="user_name">Username*</label>
							<InputText name="user_name" id="user_name" style={{"width":"100%"}} value={inputValues.user_name} disabled={true}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="name">Name*</label>
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
							<label htmlFor="function">Function</label>
							<InputText name="function" id="function" style={{"width":"100%"}} value={inputValues.function} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="email">Email address*</label>
							<InputText name="email" id="email" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={4} className="field padding-med">
							<label htmlFor="role">User role*</label>
							<Dropdown name="role" id="role" style={{"width":"100%"}} options={userRolesList} value={inputValues.role} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2} className="field padding-med">
							<label htmlFor="active">Active</label>
							<Dropdown name="active" id="active" style={{"width":"100%"}} options={activeList} value={inputValues.active} onChange={(evt) => handleChange(evt)}/>
						</Grid>						
						<Grid item xs={12} sm={12} md={12} lg={7} style={{"textAlign":"left","marginTop":"21px"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"0px"}}>
								{inputError.errorText}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={5} style={{"textAlign":"right","marginTop":"12px"}}>
							<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")} disabled={inputError.disableSave}/>
							<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
						</Grid>
					</Grid>
				</Dialog>
			);
		}else{
			return(
                <Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} className="responsive-dialog-med">
					<Grid container spacing={1} style={{"paddingTop":"12px"}}>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="user_name">Username*</label>
							<InputText name="user_name" id="user_name" style={{"width":"100%"}} value={inputValues.user_name} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="name">Name*</label>
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
							<label htmlFor="function">Function</label>
							<InputText name="function" id="function" style={{"width":"100%"}} value={inputValues.function} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
							<label htmlFor="email">Email address*</label>
							<InputText name="email" id="email" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={4} className="field padding-med">
							<label htmlFor="role">User role*</label>
							<Dropdown name="role" id="role" style={{"width":"100%"}} options={userRolesList} value={inputValues.role} onChange={(evt) => handleChange(evt)}/>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2} className="field padding-med">
							<label htmlFor="active">Active</label>
							<Dropdown name="active" id="active" style={{"width":"100%"}} options={activeList} value={inputValues.active} onChange={(evt) => handleChange(evt)}/>
						</Grid>						
						<Grid item xs={12} sm={12} md={6} lg={6} className="field padding-med">
							<label htmlFor="password">Password*</label>
							<Password name="password" id="password" style={{"width":"100%"}} value={inputValues.password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6} className="field padding-med">
							<label htmlFor="confirm_password">Confirm password*</label>
							<Password name="confirm_password" id="confirm_password" style={{"width":"100%"}} value={inputValues.confirm_password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={7} style={{"textAlign":"left","marginTop":"12px"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"0px"}}>
								{inputError.errorText}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={5} style={{"textAlign":"right","marginTop":"12px"}}>
							<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")} disabled={inputError.disableSave}/>
							<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
						</Grid>
					</Grid>
				</Dialog>
			);
		}
	}

	const changePasswordDialog = () => {
		return(
			<Dialog header={dialogValues.titleChangePassword} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openChangePassword:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} className="field padding-med">
						<label htmlFor="password">Password</label>
						<Password id="password" name="password" style={{"width":"100%"}} value={inputValues.password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
					</Grid>
					<Grid item xs={12} className="field padding-med">
						<label htmlFor="confirm_password">Confirm password</label>
						<Password id="confirm_password" name="confirm_password" style={{"width":"100%"}} value={inputValues.confirm_password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
					</Grid>
					<Grid item xs={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_password")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openChangePassword:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const sectionsDialog = () => {
		return(
			<Dialog header={dialogValues.titleSections} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openSections:false})} className="responsive-dialog-small" contentStyle={{"paddingBottom":"15px"}}>
				<Divider layout="horizontal" align="center">
					<span className="p-tag">Menu sections</span>
				</Divider>
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					{dataValues.usersMenuList && dataValues.usersMenuList.map((menu) => 
						<Grid item xs={12} sm={12} md={12} lg={6} key={menu.id}>
								<label htmlFor={menu.id} style={(inputValues.allowed_sections.indexOf(menu.id)===-1?{"float":"left","marginLeft":"30px","marginTop":"6px"}:{"float":"left","marginLeft":"30px","fontWeight":"600","marginTop":"6px"})}>{menu.name}</label>
								<p style={{"float":"right","textAlign":"center","marginRight":"48px"}}>
									<Checkbox name="sections" id={menu.id} onChange={(evt) => handleChecked(evt,menu.id)} checked={(inputValues.allowed_sections.indexOf(menu.id)>-1?true:false)}></Checkbox>
								</p>
						</Grid>
					)}
				</Grid>
				<Divider layout="horizontal" align="center">
					<span className="p-tag">Special permissions</span>
				</Divider>
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<label htmlFor="edit_customers" style={(inputValuesPermissions.edit_customers===0?{"float":"left","marginLeft":"30px","marginTop":"6px"}:{"float":"left","marginLeft":"30px","fontWeight":"600","marginTop":"6px"})}>Edit customers</label>
						<p style={{"float":"right","textAlign":"center","marginRight":"48px"}}>
							<Checkbox name="edit_customers" id="edit_customers" onChange={(evt) => handleCheckedSections(evt)} checked={(inputValuesPermissions.edit_customers===1?true:false)}></Checkbox>
						</p>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<label htmlFor="edit_customers_users" style={(inputValuesPermissions.edit_customers_users===0?{"float":"left","marginLeft":"30px","marginTop":"6px"}:{"float":"left","marginLeft":"30px","fontWeight":"600","marginTop":"6px"})}>Edit customers users</label>
						<p style={{"float":"right","textAlign":"center","marginRight":"48px"}}>
							<Checkbox name="edit_customers_users" id="edit_customers_users" onChange={(evt) => handleCheckedSections(evt)} checked={(inputValuesPermissions.edit_customers_users===1?true:false)}></Checkbox>
						</p>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<label htmlFor="delete_invoices" style={(inputValuesPermissions.delete_invoices===0?{"float":"left","marginLeft":"30px","marginTop":"6px"}:{"float":"left","marginLeft":"30px","fontWeight":"600","marginTop":"6px"})}>Delete invoices</label>
						<p style={{"float":"right","textAlign":"center","marginRight":"48px"}}>
							<Checkbox name="delete_invoices" id="delete_invoices" onChange={(evt) => handleCheckedSections(evt)} checked={(inputValuesPermissions.delete_invoices===1?true:false)}></Checkbox>
						</p>
					</Grid>
				</Grid>				
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_sections")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openSections:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const activityDialog = () => {
		return(
			<Dialog header={dialogValues.titleActivity} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openActivity:false})} className="responsive-dialog-big" contentStyle={{"paddingBottom":"15px","minHeight":"750px"}}>
				<ActivityLog session={props.session} user={currentUser}/>
			</Dialog>
		);
	}

	const tableColTemplate = (row_data,column) => {
		if(column.field==="buttons") {
			if(row_data.user_name!=="administrator") {
				return(
					<span>
						<Button label="Password" icon="pi pi-key" tooltip="Change password" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"password",row_data)}/>
						<Button label="Edit user" icon="pi pi-pencil" tooltip="Edit user" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
						<Button label="Permissions" icon="pi pi-qrcode" tooltip="User permissions" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"permissions",row_data)}/>
						<Button label="Activity" icon="pi pi-list" tooltip="User activity" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"activity",row_data)}/>
						<Button label="Delete" icon="pi pi-trash" tooltip="Delete user" className="p-button-sm p-button-raised p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
					</span>
				);
			}else{
				return(
					<span>
						<Button label="Password" icon="pi pi-key" tooltip="Change password" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"password",row_data)}/>
						<Button label="Edit user" icon="pi pi-pencil" tooltip="Edit user" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button label="Permissions" icon="pi pi-qrcode" tooltip="User permissions" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button label="Activity" icon="pi pi-list" tooltip="User activity" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"activity",row_data)}/>
						<Button label="Delete" icon="pi pi-trash" tooltip="Delete user" className="p-button-sm p-button-raised p-button-danger" disabled={true} onClick={(evt) => confirm(evt,"null",row_data)}/>
					</span>
				);
			}
		}
	}

	const tableColTemplateSmall = (row_data,column) => {
		if(column.field==="buttons") {
			if(row_data.user_name!=="administrator") {
				return(
					<span>
						<Button icon="pi pi-key" tooltip="Change password" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"password",row_data)}/>
						<Button icon="pi pi-pencil" tooltip="Edit user" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
						<Button icon="pi pi-qrcode" tooltip="User permissions" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"permissions",row_data)}/>
						<Button icon="pi pi-list" tooltip="User activity" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"activity",row_data)}/>
						<Button icon="pi pi-trash" tooltip="Delete user" className="p-button-sm p-button-raised p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
					</span>
				);
			}else{
				return(
					<span>
						<Button icon="pi pi-key" tooltip="Change password" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"password",row_data)}/>
						<Button icon="pi pi-pencil" tooltip="Edit user" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button icon="pi pi-qrcode" tooltip="User permissions" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} disabled={true} onClick={(evt) => handleButton(evt,"null",row_data)}/>
						<Button icon="pi pi-list" tooltip="User activity" className="p-button-sm p-button-raised" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"activity",row_data)}/>
						<Button icon="pi pi-trash" tooltip="Delete user" className="p-button-sm p-button-raised p-button-danger" disabled={true} onClick={(evt) => confirm(evt,"null",row_data)}/>
					</span>
				);
			}
		}
	}

	const confirm = (evt,what,elementData) => {
		if(what==="delete") {
			confirmDialog({
				message: "Are you sure you want to delete?",
				header: "Confirm delete",
				icon: "pi pi-exclamation-triangle",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<div>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={8} sm={8} md={3} lg={3} xl={3} className="field padding-med">
							<InputText name="filter_users" id="filter_users" style={{"width":"100%"}} value={inputValues.filter_users} placeholder="Filter users list..." onChange={(evt) => handleChange(evt)}/>							
						</Grid>						
						<Grid item xs={4} sm={4} md={9} lg={9} xl={9} className="field padding-med text-right">
							<Button label="Add user" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" tooltip="Add new user" style={{}} onClick={(evt) => {handleButton(evt,"add")}}/>
						</Grid>						
					</Grid>
					<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
						<div className="card">
							<DataTable
								value={usersList}
								scrollable
								scrollHeight="60vh"
								style={{"minHeight":"63vh"}}
								scrollDirection="both"
								stripedRows
								resizableColumns
								columnResizeMode="fit"
								responsiveLayout="stack"
							>
								<Column field="name" header="Name" style={{"flexGrow":"1","flexBasis":"120px"}} sortable></Column>
								<Column field="user_name" header="Username" style={{"flexGrow":"1","flexBasis":"60px"}} sortable></Column>
								<Column field="reg_date_parsed" header="Created" style={{"flexGrow":"1","flexBasis":"30px"}} sortable></Column>
								<Column field="role" header="Role" style={{"flexGrow":"1","flexBasis":"30px"}} sortable></Column>
								<Column field="buttons" header="Operations" style={{"flexGrow":"0","flexBasis":"500px"}} body={tableColTemplate}></Column>
							</DataTable>
						</div>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
						<div className="card">
							<DataTable
								value={usersList}
								responsiveLayout="stack"
							>
								<Column field="name" header="Name" sortable></Column>
								<Column field="user_name" header="Username" sortable></Column>
								<Column field="reg_date_parsed" header="Created" sortable></Column>
								<Column field="role" header="Role" sortable ></Column>
								<Column field="buttons" header="Operations" body={tableColTemplateSmall}></Column>
							</DataTable>
						</div>
					</Box>
				</div>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openChangePassword && changePasswordDialog()}
				{dialogValues.openSections && sectionsDialog()}
				{dialogValues.openActivity && activityDialog()}
            </BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default LocalUsers;
