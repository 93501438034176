import React from "react";
import {useState,useEffect} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import Grid from "@mui/material/Grid";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {ScrollPanel} from "primereact/scrollpanel";
import {ConfirmDialog} from "primereact/confirmdialog";
import {confirmDialog} from "primereact/confirmdialog";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import Message from "../Helpers/Message.js";
import {fetch_url_post,fetch_download} from "../../Sources/js/app.js";

let oper="";

let defaultInputValues={
	id: null,
	_unit_id: null,
	_group_id: null,
	customer_id: 0,
	description: "",
	category: "",
	unit_type_id: 0,
	group_id: 0,
	vat_level: 0.05,
	list_price: 0,
	observations: "",
	unit_name: "",
	abbreviation: "",
	decimal_places: 0,
	unit_observations: "",
	group_name: "",
	group_observations: "",
	currency: "DHS",
	barcode: "",
	input_price: 0,
	dimensions: 0,
	sku: "",
	quantity: 0,
};

let defaultInputValuesFilter={
	filter_description: "",
	filter_category: "All", 
	filter_group: 0,
	filter_sku: "",
}

let defaultInputValuesQuantities={
	inventory_id: null,
	quantity: "",
	filter_description: "",
	filter_sku: "",
	cost_price: 0,
	currency: "DHS",
	xe_rate: 1,
};

let defaultDataValues={
	inventoryList: [],
	unitsList: [],
	groupsList: [],
	SKUList: [],
	informationsList: [],
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
	openListUnits: false,
	titleListUnits: "",
	openListGroups: false,
	titleListGroups: "",
	openAddEditUnits: false,
	titleAddEditUnits: "",
	openAddEditGroups: false,
	titleAddEditGroups: "",
	openAddEditQuantities: false,
	titleAddEditQuantities: "Add new quantity",
	openStockInformations: false,
	titleStockInformations: "Item's stock Informations",
};

let categoryList=[
	{label: "Goods",value: "Goods"},
	{label: "Services",value: "Services"},
];

let categoryListFilter=[
	{label: "All categories",value: "All"},
	{label: "Goods",value: "Goods"},
	{label: "Services",value: "Services"},
];

let groupListFilter=[
	{group_name: "All groups",id: 0},
];

let VATLevelsList=[
	{label: "0%",value: 0},
	{label: "5%",value: 0.05},
];

let defaultInputError={
	disableSave: false,
	errorText: "",
};

const Inventory = (props) => {

	var timerId=null;
	defaultInputValues.customer_id=(props.customer.id?props.customer.id:null);

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [inputValuesFilter, setInputValuesFilter] = useState(defaultInputValuesFilter);
	const [inputValuesQuantities, setInputValuesQuantities] = useState(defaultInputValuesQuantities);
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [inventoryList, setInventoryList] = useState([]);
	const [inputError, setInputError] = useState(defaultInputError);	
	const [isEdit, setIsEdit] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

	let currencyList=[
		{
			label: "DHS",value: "DHS",xe_rate: 1,
		},
		{
			label: "USD",value: "USD",xe_rate: (parseFloat(props.session.xe_rate_usd)?parseFloat(props.session.xe_rate_usd):1),
		},
		{
			label: "EUR",value: "EUR",xe_rate: (parseFloat(props.session.xe_rate_eur)?parseFloat(props.session.xe_rate_eur):1),
		},
		{
			label: "GBP",value: "GBP",xe_rate: (parseFloat(props.session.xe_rate_gbp)?parseFloat(props.session.xe_rate_gbp):1),
		},
	];

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="sku") {
			if(inputError.errorText!=="") {
				setInputError(defaultInputError);
			}
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleChangeQuantities = evt => {
		const {name, value} = evt.target;
		let save_state=1;
		if((name==="quantity")||(name==="xe_rate")) {
			if(!evt.target.validity.valid) {
				setMessageText("Error: Only numbers are allowed!");							
				return false;
			}
		}
		if(name==="currency") {
			save_state=0;
			console.log(value);
			setInputValuesQuantities({...inputValuesQuantities,[name]:value,xe_rate:currencyList.find(currency => currency.value===value)?.xe_rate});			
		}
		if(save_state) {
			setInputValuesQuantities({...inputValuesQuantities,[name]:value});
		}
	}

	const validateSKU = evt => {
		const {name, value} = evt.target;
		let found=false;
		if(dataValues.inventoryList.length>0) {
			dataValues.inventoryList.forEach(element => {
				if(value.toUpperCase()===element.sku.toUpperCase()) {
					found=true;
					return;
				}
			});
		}
		let tmp={
			disableSave: found,
			errorText: (found===true?"SKU already exists. Use add quantity.":""),
		}
		setInputError(tmp);
	}

	const handleChangeFilter = evt => {
		const {name, value} = evt.target;
		if(name==="filter_description") {
			setInventoryList(dataValues.inventoryList
													.filter((item)=>((item.description.toLowerCase().indexOf(value.toLowerCase())>-1))||(value===""))
													.filter((item)=>((item.sku.toLowerCase().indexOf(inputValuesFilter.filter_sku.toLowerCase())>-1))||(inputValuesFilter.filter_sku===""))
													.filter((item)=>((item.category===inputValuesFilter.filter_category))||(inputValuesFilter.filter_category==="All"))
													.filter((item)=>((item.group_id===inputValuesFilter.filter_group))||(inputValuesFilter.filter_group===0))
			);
		}
		if(name==="filter_category") {
			setInventoryList(dataValues.inventoryList
													.filter((item)=>((item.description.toLowerCase().indexOf(inputValuesFilter.filter_description.toLowerCase())>-1))||(inputValuesFilter.filter_description===""))
													.filter((item)=>((item.sku.toLowerCase().indexOf(inputValuesFilter.filter_sku.toLowerCase())>-1))||(inputValuesFilter.filter_sku===""))
													.filter((item)=>((item.category===value))||(value==="All"))
													.filter((item)=>((item.group_id===inputValuesFilter.filter_group))||(inputValuesFilter.filter_group===0))
			);
		}
		if(name==="filter_group") {
			setInventoryList(dataValues.inventoryList
													.filter((item)=>((item.description.toLowerCase().indexOf(inputValuesFilter.filter_description.toLowerCase())>-1))||(inputValuesFilter.filter_description===""))
													.filter((item)=>((item.sku.toLowerCase().indexOf(inputValuesFilter.filter_sku.toLowerCase())>-1))||(inputValuesFilter.filter_sku===""))
													.filter((item)=>((item.category===inputValuesFilter.filter_category))||(inputValuesFilter.filter_category==="All"))
													.filter((item)=>((item.group_id===value))||(value===0))
			);
		}
		if(name==="filter_sku") {
			setInventoryList(dataValues.inventoryList
													.filter((item)=>((item.description.toLowerCase().indexOf(inputValuesFilter.filter_description.toLowerCase())>-1))||(inputValuesFilter.filter_description===""))
													.filter((item)=>((item.sku.toLowerCase().indexOf(value.toLowerCase())>-1))||(value===""))
													.filter((item)=>((item.category===inputValuesFilter.filter_category))||(inputValuesFilter.filter_category==="All"))
													.filter((item)=>((item.group_id===inputValuesFilter.filter_group))||(inputValuesFilter.filter_group===0))
			);
		}
		setInputValuesFilter({...inputValuesFilter,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setIsEdit(false);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Add new inventory item"});
		}
		if(what==="edit") {
			setInputValues({...elementData});
			setIsEdit(true);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Edit inventory item"});
		}
		if(what==="list_units") {
			setDialogValues({...dialogValues,openListUnits: true,titleListUnits: "Defined units"});
		}
		if(what==="list_groups") {
			setDialogValues({...dialogValues,openListGroups: true,titleListGroups: "Defined groups"});
		}
		if(what==="add_unit") {
			setInputValues({...inputValues,unit_name:"",
											abbreviation:"",
											decimal_places:"",
											unit_observations:""
			});
			setDialogValues({...dialogValues,openAddEditUnits: true,titleAddEditUnits: "Add new unit"});
		}
		if(what==="add_group") {
			setInputValues({...inputValues,group_name:"",
											group_observations:""
			});
			setDialogValues({...dialogValues,openAddEditGroups: true,titleAddEditGroups: "Add new group"});
		}
		if(what==="edit_unit") {
			setInputValues({...inputValues,_unit_id:elementData.id,
										unit_name:elementData.unit_name,
										abbreviation:elementData.abbreviation,
										decimal_places:elementData.decimal_places,
										unit_observations:elementData.unit_observations
			});
			setDialogValues({...dialogValues,openAddEditUnits: true,titleAddEditUnits: "Edit unit"});
		}
		if(what==="edit_group") {
			setInputValues({...inputValues,_group_id:elementData.id,
										group_name:elementData.group_name,
										group_observations:elementData.group_observations
			});
			setDialogValues({...dialogValues,openAddEditGroups: true,titleAddEditGroups: "Edit group"});
		}
		if(what==="save") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.description==="") {
				setMessageText("Error: Please fill description!");				
				document.getElementById("description").classList.add("required_input_error");
				return false;
			}
			if(inputValues.category==="") {
				setMessageText("Error: Please select category!");				
				document.getElementById("category").classList.add("required_input_error");
				return false;
			}
			if(inputValues.unit_type_id===0) {
				setMessageText("Error: Please select unit!");				
				document.getElementById("unit_type_id").classList.add("required_input_error");
				return false;
			}
			if(inputValues.group_id===0) {
				setMessageText("Error: Please select group!");				
				document.getElementById("group_id").classList.add("required_input_error");
				return false;
			}
			if((inputValues.input_price==="")||(inputValues.input_price==0)) {
				setMessageText("Error: Please specify unit price!");				
				document.getElementById("input_price").classList.add("required_input_error");
				return false;
			}
			if(inputValues.category!=="Services") {
				if((inputValues.quantity==="")||(inputValues.quantity==0)) {
					setMessageText("Error: Please specify quantity!");				
					document.getElementById("quantity").classList.add("required_input_error");
					return false;
				}
			}
			let url="saveInventoryItem";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data={...inputValues};
			data.customer_id=props.customer.id;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save_unit") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.unit_name==="") {
				setMessageText("Error: Please fill unit name!");				
				document.getElementById("unit_name").classList.add("required_input_error");
				return false;
			}
			if(inputValues.abbreviation==="") {
				setMessageText("Error: Please fill abbreviation!");				
				document.getElementById("abbreviation").classList.add("required_input_error");
				return false;
			}
			if(inputValues.decimal_places==="") {
				setMessageText("Error: Please fill decimal places!");				
				document.getElementById("decimal_places").classList.add("required_input_error");
				return false;
			}
			let url="saveInventoryUnitType";
			url+="?session_id="+props.session.sessId;
			oper="save_unit";
			let data={...inputValues};
			data.id=inputValues._unit_id;
			data.customer_id=props.customer.id;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save_group") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(inputValues.group_name==="") {
				setMessageText("Error: Please fill group name!");				
				document.getElementById("group_name").classList.add("required_input_error");
				return false;
			}
			let url="saveInventoryGroup";
			url+="?session_id="+props.session.sessId;
			oper="save_group";
			let data={...inputValues};
			data.id=inputValues._group_id;
			data.customer_id=props.customer.id;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete") {
			let url="deleteInventoryItem";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
				customer_id: props.customer.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete_unit") {
			let url="deleteInventoryUnitType";
			url+="?session_id="+props.session.sessId;
			oper="delete_unit";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete_group") {
			let url="deleteInventoryGroup";
			url+="?session_id="+props.session.sessId;
			oper="delete_group";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="pictures") {
			setDialogValues({...dialogValues,openPictures: true,titlePictures: "Pictures for item"});
		}
		if(what==="quantities") {
			setInputValuesQuantities({...inputValuesQuantities,inventory_id:elementData.id});
			oper="get_quantities";
			let url="getInventoryOperations";
			url+="?session_id="+props.session.sessId;
			let data={
				customer_id: props.customer.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);		
		}
		if(what==="save_quantity") {
			document.querySelectorAll("input").forEach((element)=>element.classList.remove("required_input_error"));
			if(!inputValuesQuantities.inventory_id) {
				setMessageText("Error: Please select item!");				
				document.getElementById("inventory_id").classList.add("required_input_error");
				return false;
			}
			if((!inputValuesQuantities.quantity)||(inputValuesQuantities.quantity==="0")) {
				setMessageText("Error: Please specify quantity!");				
				document.getElementById("quantity").classList.add("required_input_error");
				return false;
			}
			if((inputValuesQuantities.cost_price==="")||(inputValuesQuantities.cost_price==0)) {
				setMessageText("Error: Please specify unit price!");				
				document.getElementById("cost_price").classList.add("required_input_error");
				return false;
			}
			let url="saveInventoryOperation";
			url+="?session_id="+props.session.sessId;
			oper="save_quantity";
			let data={...inputValuesQuantities};
			data.customer_id=props.customer.id;
			data.xe_rate=(inputValuesQuantities.currency==="DHS"?1:inputValuesQuantities.xe_rate);
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}	
		if(what==="stock_informations") {
			setInputValuesQuantities({...inputValuesQuantities,inventory_id:elementData.id});
			oper="get_stock_informations";
			let url="getInventoryItemStockInformations";
			url+="?session_id="+props.session.sessId;
			let data={
				sku: elementData.sku,
			};
			fetch_url_post(url,data,success_handler,fail_handler);		
		}
		if(what==="view_stock_informations_document") {
			if(elementData.type==="DECREASE STOCK") {
				//we have an invoice
				let url="getPdfInvoice";
				let oper="download";
				url+="?session_id="+props.session.sessId;
				let data={
					id: elementData.document_id,
				};
				fetch_download(url,data,"Invoice_"+elementData.filename_on_disk+".pdf",success_handler);
				setMessageText("Downloading file, please wait...");
			}else{
				//we have a stock operation hence an expenses file
				let url="getFile";
				let oper="download";
				url+="?session_id="+props.session.sessId+"&file="+elementData.filename_on_disk;
				let data={};
				fetch_download(url,data,elementData.document,success_handler);
				setMessageText("Downloading file, please wait...");
			}
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Error: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			navigate("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save")||(oper==="delete")) {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,inventoryList:(result.inventoryList?result.inventoryList:[]),
										unitsList:(result.unitsList?result.unitsList:[]),
										groupsList:(result.groupsList?result.groupsList:[])
			});
			setInventoryList(result.inventoryList?result.inventoryList
																		.filter((item)=>((item.description.toLowerCase().indexOf(inputValuesFilter.filter_description.toLowerCase())>-1))||(inputValuesFilter.filter_description===""))
																		.filter((item)=>((item.sku.toLowerCase().indexOf(inputValuesFilter.filter_sku.toLowerCase())>-1))||(inputValuesFilter.filter_sku===""))
																		.filter((item)=>((item.category===inputValuesFilter.filter_category))||(inputValuesFilter.filter_category==="All"))
																		.filter((item)=>((item.group_id===inputValuesFilter.filter_group))||(inputValuesFilter.filter_group===0))
																		:[]);
			groupListFilter=[
				{group_name: "All groups",id: 0},
			];
			for(var i=0;i<result.groupsList.length;i++) {
				groupListFilter.push(result.groupsList[i]);
			}
            if(!dataOk) {
                setDataOk(true);
            }
			setIsEdit(false);
			oper="";
		}
		if(oper==="save_unit") {
			setDialogValues({...dialogValues,openAddEditUnits: false});
			setDataValues({...dataValues,unitsList:(result.unitsList?result.unitsList:[])});
			oper="";
		}
		if(oper==="save_group") {
			setDialogValues({...dialogValues,openAddEditGroups: false});
			setDataValues({...dataValues,groupsList:(result.groupsList?result.groupsList:[])});
			groupListFilter=[
				{group_name: "All groups",id: 0},
			];
			for(var i=0;i<result.groupsList.length;i++) {
				groupListFilter.push(result.groupsList[i]);
			}
			oper="";
		}
		if(oper==="delete_unit") {
			setDataValues({...dataValues,unitsList:(result.unitsList?result.unitsList:[])});
			oper="";
		}
		if(oper==="delete_group") {
			setDataValues({...dataValues,groupsList:(result.groupsList?result.groupsList:[])});
			groupListFilter=[
				{group_name: "All groups",id: 0},
			];
			for(var i=0;i<result.groupsList.length;i++) {
				groupListFilter.push(result.groupsList[i]);
			}
			oper="";
		}
		if(oper==="save_quantity") {
			setDialogValues(defaultDialogValues);			
			const updatedItems=inventoryList.map(item => {
				if(item.id===inputValuesQuantities.inventory_id) {
					return {...item,quantity:((item.quantity*1)+(inputValuesQuantities.quantity*1)).toFixed(2) };
				}
				return item;
			});
			setInventoryList(updatedItems);
			setInputValuesQuantities(defaultInputValuesQuantities);
		}
		if(oper==="get_quantities") {
			setDataValues({...dataValues,SKUList:(result.inventoryList?result.inventoryList:[])});
			setDialogValues({...dialogValues,openAddEditQuantities: true});
			oper="";
		}
		if(oper==="get_stock_informations") {
			setDataValues({...dataValues,informationsList:(result.informationsList?result.informationsList:[])});
			setDialogValues({...dialogValues,openStockInformations: true});
			oper="";
		}
		if(oper==="download") {
			setMessageText(null);
			setBlockScreen(false);
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("Connection error please try again later!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operation is in progress. Please wait...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			if(props.customer.id) {
				oper="get";
				let url="getInventoryItems";
				url+="?session_id="+props.session.sessId;
				let data={
					customer_id: props.customer.id,
				};
				fetch_url_post(url,data,success_handler,fail_handler);
			}else{
				navigate("/");
			}
		}else{
			navigate("/login");
		}
	},[]);

	const addEditDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} className="responsive-dialog-med-big">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={9} className="field padding-med">
						<label htmlFor="description">Description*</label>
						<InputText name="description" id="description" style={{"width":"100%"}} value={inputValues.description} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={6} sm={6} md={5} lg={3} className="field padding-med">
						<label htmlFor="barcode">Barcode</label>
						<InputText name="barcode" id="barcode" style={{"width":"100%"}} value={inputValues.barcode} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={6} sm={6} md={5} lg={3} className="field padding-med">
						<label htmlFor="sku">SKU</label>
						<InputText name="sku" id="sku" style={{"width":"100%"}} value={inputValues.sku} onChange={(evt) => handleChange(evt)}  onBlur={(evt) => validateSKU(evt)}/>
					</Grid>
					<Grid item xs={12} sm={6} md={12} lg={2} className="field padding-med">
						<label htmlFor="category">Category*</label>
						<Dropdown name="category" id="category" style={{"width":"100%"}} options={categoryList} value={inputValues.category} onChange={(evt) => handleChange(evt)}/>
					</Grid>						
					<Grid item xs={12} sm={6} md={6} lg={3} className="field padding-med">
						<div style={{"width":"80%","float":"left"}}>
							<label htmlFor="unit_type_id">Unit*</label>
							<Dropdown name="unit_type_id" id="unit_type_id" style={{"width":"100%"}} options={dataValues.unitsList} value={inputValues.unit_type_id} optionLabel="unit_name" optionValue="id" onChange={(evt) => handleChange(evt)}/>
						</div>
						<div style={{"width":"19%","float":"left","paddingTop":"1.6em"}}>
							<Button icon="pi pi-pencil" className="p-button-sm p-button-primary p-button-raised" style={{"float":"right","marginTop":"-1px"}} onClick={(evt) => handleButton(evt,"list_units")}/>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4} className="field padding-med">
						<div style={{"width":"85%","float":"left"}}>
							<label htmlFor="group_id">Group*</label>
							<Dropdown name="group_id" id="group_id" style={{"width":"100%"}} options={dataValues.groupsList} value={inputValues.group_id} optionLabel="group_name" optionValue="id" onChange={(evt) => handleChange(evt)}/>
						</div>
						<div style={{"width":"14%","float":"left","paddingTop":"1.6em"}}>
							<Button icon="pi pi-pencil" className="p-button-sm p-button-primary p-button-raised" style={{"float":"right","marginTop":"-1px"}} onClick={(evt) => handleButton(evt,"list_groups")}/>
						</div>
					</Grid>
					{!!(props.customer.vat_registered===1) &&
						<React.Fragment>
							<Grid item xs={6} sm={6} md={3} lg={2} className="field padding-med">
								<label htmlFor="vat_level">VAT level</label>
								<Dropdown name="vat_level" id="vat_level" style={{"width":"100%"}} options={VATLevelsList} value={inputValues.vat_level} onChange={(evt) => handleChange(evt)}/>
							</Grid>	
							<Grid item xs={6} sm={6} md={3} lg={2} className="field padding-med">
								<label htmlFor="list_price">Retail price</label>
								<InputText name="list_price" id="list_price" style={{"width":"100%"}} value={inputValues.list_price} onChange={(evt) => handleChange(evt)}/>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={2} className="field padding-med">
								<label htmlFor="input_price">Cost price/unit*</label>
								<InputText name="input_price" id="input_price" style={{"width":"100%"}} value={inputValues.input_price} onChange={(evt) => handleChange(evt)}/>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={2} className="field padding-med">
								<label htmlFor="currency">Currency</label>
								<Dropdown name="currency" id="currency" style={{"width":"100%"}} options={currencyList} value={inputValues.currency} onChange={(evt) => handleChange(evt)}/>
							</Grid>						
							<Grid item xs={12} sm={6} md={6} lg={2} className="field padding-med">
								<label htmlFor="quantity">Quantity*</label>
								<InputText name="quantity" id="quantity" style={{"width":"100%"}} value={(inputValues.category==="Services"?"NA":inputValues.quantity)} disabled={(isEdit||inputValues.category==="Services")} onChange={(evt) => handleChange(evt)}/>
							</Grid>	
							<Grid item xs={12} sm={6} md={6} lg={2} className="field padding-med">
								<label htmlFor="dimensions">Dimensions(cm)</label>
								<InputText name="dimensions" id="dimensions" style={{"width":"100%"}} value={inputValues.dimensions} onChange={(evt) => handleChange(evt)}/>
							</Grid>	
						</React.Fragment>
					}
					{!!(props.customer.vat_registered===0) &&
						<React.Fragment>
							<Grid item xs={6} sm={4} md={3} lg={2} className="field padding-med">
								<label htmlFor="list_price">Retail price</label>
								<InputText name="list_price" id="list_price" style={{"width":"100%"}} value={inputValues.list_price} onChange={(evt) => handleChange(evt)}/>
							</Grid>
							<Grid item xs={6} sm={4} md={3} lg={2} className="field padding-med">
								<label htmlFor="input_price">Cost price/unit*</label>
								<InputText name="input_price" id="input_price" style={{"width":"100%"}} value={inputValues.input_price} onChange={(evt) => handleChange(evt)}/>
							</Grid>
							<Grid item xs={6} sm={4} md={3} lg={2} className="field padding-med">
								<label htmlFor="currency">Currency</label>
								<Dropdown name="currency" id="currency" style={{"width":"100%"}} options={currencyList} value={inputValues.currency} onChange={(evt) => handleChange(evt)}/>
							</Grid>						
							<Grid item xs={6} sm={6} md={3} lg={2} className="field padding-med">
								<label htmlFor="quantity">Quantity*</label>
								<InputText name="quantity" id="quantity" style={{"width":"100%"}} value={(inputValues.category==="Services"?"NA":inputValues.quantity)} disabled={(isEdit||inputValues.category==="Services")} onChange={(evt) => handleChange(evt)}/>
							</Grid>	
							<Grid item xs={6} sm={6} md={12} lg={4} className="field padding-med">
								<label htmlFor="dimensions">Dimensions(cm)</label>
								<InputText name="dimensions" id="dimensions" style={{"width":"100%"}} value={inputValues.dimensions} onChange={(evt) => handleChange(evt)}/>
							</Grid>	
						</React.Fragment>
					}
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="observations">Observations</label>
						<InputText name="observations" id="observations" style={{"width":"100%"}} value={inputValues.observations} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={9} className="field padding-med">
						<p style={{"fontWeight":"600","color":"#FF0000","marginTop":"12px"}}>{inputError.errorText}</p>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={3} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")} disabled={inputError.disableSave}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const addEditQuantitiesDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEditQuantities} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEditQuantities:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px","maxHeight":"72vh"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="inventory_id">Product*</label>
						<Dropdown name="inventory_id" id="inventory_id" style={{"width":"100%"}} options={dataValues.SKUList} filter value={inputValuesQuantities.inventory_id} optionLabel="sku_description" optionValue="id" disabled={true}/>
					</Grid>
					{!!(inputValuesQuantities.currency==="DHS") &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={4} lg={4} className="field padding-med">
								<label htmlFor="quantity">Quantity*</label>
								<InputText name="quantity" id="quantity" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValuesQuantities.quantity} onChange={(evt) => handleChangeQuantities(evt)}/>							
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} className="field padding-med">
								<label htmlFor="unit_price">Cost price/unit*</label>
								<div className="p-inputgroup">
									<InputText name="cost_price" id="cost_price" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValuesQuantities.cost_price} onChange={(evt) => handleChangeQuantities(evt)}/>
									<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{inputValues.currency}</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} className="field padding-med">
								<label htmlFor="currency">Currency*</label>
								<Dropdown name="currency" id="currency" style={{"width":"100%","height":"40px"}} options={currencyList} value={inputValuesQuantities.currency} onChange={(evt) => handleChangeQuantities(evt)}/>
							</Grid>						
						</React.Fragment>
					}
					{!!(inputValuesQuantities.currency!=="DHS") &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
								<label htmlFor="quantity">Quantity*</label>
								<InputText name="quantity" id="quantity" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValuesQuantities.quantity} onChange={(evt) => handleChangeQuantities(evt)}/>							
							</Grid>
							<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
								<label htmlFor="unit_price">Cost price/unit*</label>
								<div className="p-inputgroup">
									<InputText name="cost_price" id="cost_price" style={{"width":"100%"}} pattern="[0-9.]*" inputmode="numeric" value={inputValuesQuantities.cost_price} onChange={(evt) => handleChangeQuantities(evt)}/>
									<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>{inputValues.currency}</span>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
								<label htmlFor="currency">Currency*</label>
								<Dropdown name="currency" id="currency" style={{"width":"100%","height":"40px"}} options={currencyList} value={inputValuesQuantities.currency} onChange={(evt) => handleChangeQuantities(evt)}/>
							</Grid>						
							<Grid item xs={12} sm={12} md={3} lg={3} className="field padding-med">
								<label htmlFor="quantity">Exchange rate*</label>
								<div className="p-inputgroup">
									<InputText name="xe_rate" id="xe_rate" style={{"width":"100%"}} pattern={"[0-9.]*"} inputmode="numeric" value={inputValuesQuantities.xe_rate} onChange={(evt) => handleChangeQuantities(evt)}/>
									<span className="p-inputgroup-addon" style={{"backgroundColor":"lightgrey"}}>DHS</span>
								</div>
							</Grid>
						</React.Fragment>
					}
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_quantity")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEditQuantities:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const listUnitsDialog = () => {
		return(
			<Dialog header={dialogValues.titleListUnits} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openListUnits:false})} className="responsive-dialog-med" contentStyle={{"paddingBottom":"15px","minHeight":"600px"}}>
				<div>
					<div style={{"width":"100%","float":"right","paddingRight":"12px"}}>
						<Button label="Add new unit" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" style={{"float":"right","marginBottom":"12px","marginTop":"3px"}} onClick={(evt) => {handleButton(evt,"add_unit")}}/>
					</div>
					<br style={{"clear":"both"}}/>
					<DataTable
						value={dataValues.unitsList}
						scrollable
						scrollHeight="60vh"
						style={{"minHeight":"63vh"}}
						scrollDirection="both"
						stripedRows
						resizableColumns
						columnResizeMode="fit"
						responsiveLayout="stack"
					>
						<Column field="unit_name" header="Name" style={{"flexGrow":"1","flexBasis":"190px"}} sortable></Column>
						<Column field="abbreviation" header="Abbreviation" style={{"flexGrow":"1","flexBasis":"25px"}} sortable></Column>
						<Column field="decimal_places" header="Decimal places" style={{"flexGrow":"1","flexBasis":"25px"}} sortable></Column>
						<Column field="buttons" header="Operations" style={{"flexGrow":"1","flexBasis":"21px"}} body={tableColTemplateUnits}></Column>
					</DataTable>
				</div>
			</Dialog>
		);
	}

	const listGroupsDialog = () => {
		return(
			<Dialog header={dialogValues.titleListGroups} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openListGroups:false})} className="responsive-dialog-small" contentStyle={{"paddingBottom":"15px","minHeight":"600px"}}>
				<div>
					<div style={{"width":"100%","float":"right","paddingRight":"12px"}}>
						<Button label="Add new group" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" style={{"float":"right","marginBottom":"12px","marginTop":"3px"}} onClick={(evt) => {handleButton(evt,"add_group")}}/>
					</div>
					<br style={{"clear":"both"}}/>
					<DataTable
						value={dataValues.groupsList}
						scrollable
						scrollHeight="60vh"
						style={{"minHeight":"63vh"}}
						scrollDirection="both"
						stripedRows
						resizableColumns
						columnResizeMode="fit"
						responsiveLayout="stack"
					>
						<Column field="group_name" header="Name" style={{"flexGrow":"1","flexBasis":"210px"}} sortable></Column>
						<Column field="buttons" header="Operations" style={{"flexGrow":"1","flexBasis":"15px"}} body={tableColTemplateGroups}></Column>
					</DataTable>
				</div>
			</Dialog>
		);
	}

	const addEditUnitsDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEditUnits} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEditUnits:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={6} className="field padding-med">
						<label htmlFor="unit_name">Unit name*</label>
						<InputText name="unit_name" id="unit_name" style={{"width":"100%"}} value={inputValues.unit_name} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={3} className="field padding-med">
						<label htmlFor="abbreviation">Abbreviation*</label>
						<InputText name="abbreviation" id="abbreviation" style={{"width":"100%"}} value={inputValues.abbreviation} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={5} sm={5} md={5} lg={3} className="field padding-med">
						<label htmlFor="decimal_places">Decimals*</label>
						<InputNumber name="decimal_places" id="decimal_places" style={{"width":"100%"}} value={inputValues.decimal_places} onChange={(evt) => setInputValues({...inputValues,decimal_places:evt.value})} onValueChange={(evt) => setInputValues({...inputValues,decimal_places:evt.value})} showButtons mode="decimal"/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="unit_observations">Observations</label>
						<InputText name="unit_observations" id="group_observations" style={{"width":"100%"}} value={inputValues.unit_observations} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_unit")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEditUnits:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const addEditGroupsDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEditGroups} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEditGroups:false})} className="responsive-dialog-small">
				<Grid container spacing={1} style={{"paddingTop":"12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="group_name">Group name*</label>
						<InputText name="group_name" id="group_name" style={{"width":"100%"}} value={inputValues.group_name} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} className="field padding-med">
						<label htmlFor="group_observations">Observations</label>
						<InputText name="group_observations" id="group_observations" style={{"width":"100%"}} value={inputValues.group_observations} onChange={(evt) => handleChange(evt)}/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginTop":"12px"}}>
						<Button label="Save" icon="pi pi-check-circle" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_group")}/>
						<Button label="Close" icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" onClick={(evt)=>setDialogValues({...dialogValues,openAddEditGroups:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const stockInformationsDialog = () => {
		return(
			<Dialog header={dialogValues.titleStockInformations} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openStockInformations:false})} className="responsive-dialog-big" contentStyle={{"paddingBottom":"15px","minHeight":"600px"}}>
				<ScrollPanel style={{"width":"100%","height":"90%"}}>
					<DataTable
						value={dataValues.informationsList}
						scrollable
						scrollHeight="60vh"
						style={{"minHeight":"63vh"}}
						scrollDirection="both"
						stripedRows
						resizableColumns
						columnResizeMode="fit"
						responsiveLayout="stack"
					>
						<Column field="type" header="Type" style={{"flexGrow":"1","flexBasis":"75px"}} sortable></Column>
						<Column field="document" header="Document" style={{"flexGrow":"1","flexBasis":"255px"}} sortable body={tableColTemplateInformations}></Column>
						<Column field="quantity" header="Quantity" style={{"flexGrow":"1","flexBasis":"45px"}} sortable></Column>
						<Column field="user_name" header="Operated by" style={{"flexGrow":"1","flexBasis":"120px"}} sortable></Column>						
						<Column field="reg_date_parsed" header="Date" style={{"flexGrow":"1","flexBasis":"60px"}} sortable></Column>
						<Column field="buttons" header="" style={{"flexGrow":"0","flexBasis":"55px"}} body={tableColTemplateInformations}></Column>
					</DataTable>
				</ScrollPanel>
			</Dialog>
		);
	}

	const confirm = (evt,what,elementData) => {
		if((what==="delete")||(what==="delete_unit")||(what==="delete_group")) {
			confirmDialog({
				message: "Are you sure you want to delete?",
				header: "Confirm delete",
				icon: "pi pi-exclamation-triangle",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	const tableColTemplateUnits = (row_data,column) => {
		return(
			<span>
				<Button icon="pi pi-pencil" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px","marginTop":"-6px"}} onClick={(evt) => handleButton(evt,"edit_unit",row_data)}/>
				<Button icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" style={{"marginTop":"-6px"}} onClick={(evt) => confirm(evt,"delete_unit",row_data)}/>
			</span>
		);
	}

	const tableColTemplateGroups = (row_data,column) => {
		return(
			<span>
				<Button icon="pi pi-pencil" className="p-button-sm p-button-success p-button-raised" style={{"marginRight":"6px","marginTop":"-6px"}} onClick={(evt) => handleButton(evt,"edit_group",row_data)}/>
				<Button icon="pi pi-times" className="p-button-sm p-button-danger p-button-raised" style={{"marginTop":"-6px"}} onClick={(evt) => confirm(evt,"delete_group",row_data)}/>
			</span>
		);
	}

	const tableColTemplate = (row_data,column) => {
		if(column.field==="category") {
			return(
				<span>
					{row_data.category} - {row_data.group_name}
				</span>
			);
		}
		if(column.field==="quantity") {
			if(row_data.category==="Services") {
				return(
					<span>
						NA
					</span>
				);
			}else{
				return(
					<span>
						{row_data.quantity} {row_data.abbreviation}
					</span>
				);
			}
		}
		if(column.field==="buttons") {
			return(
				<span>
					<Button icon="pi pi-shopping-cart" tooltip="Add quantity" className="mr-2 mb-2 p-button-primary" style={{"marginRight":"3px"}} disabled={(row_data.category==="Services"?true:false)} onClick={(evt) => handleButton(evt,"quantities",row_data)}/>
					<Button icon="pi pi-info-circle" tooltip="Stock informations" className="mr-2 mb-2 p-button-primary" style={{"marginRight":"3px"}} disabled={(row_data.category==="Services"?true:false)} onClick={(evt) => handleButton(evt,"stock_informations",row_data)}/>
					<Button icon="pi pi-pencil" tooltip="Edit item" className="mr-2 mb-2 p-button-primary" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
					<Button icon="pi pi-trash" tooltip="Delete item" className="mr-2 mb-2 p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateSmall = (row_data,column) => {
		if(column.field==="buttons") {
			return(
				<span>
					<Button icon="pi pi-shopping-cart" tooltip="Add quantity" className="mr-2 mb-2 p-button-primary" style={{"marginRight":"3px"}} disabled={(row_data.category==="Services"?true:false)} onClick={(evt) => handleButton(evt,"quantities",row_data)}/>
					<Button icon="pi pi-info-circle" tooltip="Stock informations" className="mr-2 mb-2 p-button-primary" style={{"marginRight":"3px"}} disabled={(row_data.category==="Services"?true:false)} onClick={(evt) => handleButton(evt,"stock_informations",row_data)}/>
					<Button icon="pi pi-pencil" tooltip="Edit item" className="mr-2 mb-2 p-button-primary" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
					<Button icon="pi pi-trash" tooltip="Delete item" className="mr-2 mb-2 p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
				</span>
			);
		}
	}

	const tableColTemplateInformations = (row_data,column) => {
		if(column.field==="buttons") {
			return(
				<span>
					<Button icon="pi pi-download" tooltip="View document" className="mr-2 mb-2 p-button-primary" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"view_stock_informations_document",row_data)} disabled={row_data.document?false:true}/>
				</span>
			);
		}
		if(column.field==="document") {
			if(row_data.document) {
				return(
					<span>
						{row_data.document} ({row_data.debug})
					</span>
				);
			}else{
				return(
					<span>
						QUANTITY ADDED FROM INVENTORY ({row_data.debug})
					</span>
				);
			}
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" } }}>
					<div>
						<div style={{"width":"100%","marginBottom":"9px"}}>
							<InputText name="filter_description" id="filter_description" style={{"width":"100%"}} value={inputValuesFilter.filter_description} placeholder="Filter by description..." onChange={(evt) => handleChangeFilter(evt)}/>							
						</div>
						<div style={{"width":"100%","marginBottom":"9px"}}>
							<InputText name="filter_sku" id="filter_sku" style={{"width":"100%"}} value={inputValuesFilter.filter_sku} placeholder="Filter by SKU..." onChange={(evt) => handleChangeFilter(evt)}/>							
						</div>
						<div style={{"width":"100%","marginBottom":"9px"}}>
							<Dropdown name="filter_category" id="filter_category" style={{"width":"100%"}} options={categoryListFilter} value={inputValuesFilter.filter_category} onChange={(evt) => handleChangeFilter(evt)}/>				
						</div>
						<div style={{"width":"100%","marginBottom":"9px"}}>
							<Dropdown name="filter_group" id="filter_group" style={{"width":"100%"}} options={groupListFilter} value={inputValuesFilter.filter_group} optionLabel="group_name" optionValue="id" onChange={(evt) => handleChangeFilter(evt)}/>
						</div>
						<div style={{"textAlign":"right"}}>
							<Button label="Add new item" icon="pi pi-plus-circle" className="p-button-sm p-button-primary p-button-raised" style={{"marginBottom":"9px","float":"right"}} onClick={(evt) => {handleButton(evt,"add")}}/>
						</div>
						<div style={{"marginTop":"6px"}}>
							<DataTable
								value={inventoryList}
								responsiveLayout="stack"
								stripedRows
							>
								<Column field="description" header="" style={{"fontWeight":"600"}} sortable></Column>
								<Column field="category" header="Category - Group" style={{"flexGrow":"1","flexBasis":"90px"}} body={tableColTemplateSmall} sortable></Column>
								<Column field="quantity" header="QTY." style={{"flexGrow":"1","flexBasis":"90px"}} body={tableColTemplateSmall}></Column>
								<Column field="buttons" header="" style={{}} body={tableColTemplateSmall}></Column>
							</DataTable>
						</div>
					</div>
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
					<div>
						<div style={{"width":"20%","float":"left","paddingLeft":"12px"}}>
							<InputText name="filter_description" id="filter_description" style={{"width":"100%"}} value={inputValuesFilter.filter_description} placeholder="Filter by description..." onChange={(evt) => handleChangeFilter(evt)}/>
						</div>
						<div style={{"width":"14%","float":"left","paddingLeft":"12px"}}>
							<InputText name="filter_sku" id="filter_sku" style={{"width":"100%"}} value={inputValuesFilter.filter_sku} placeholder="Filter by SKU..." onChange={(evt) => handleChangeFilter(evt)}/>
						</div>
						<div style={{"width":"15%","float":"left","paddingLeft":"12px"}}>
							<Dropdown name="filter_category" id="filter_category" style={{"width":"100%"}} options={categoryListFilter} value={inputValuesFilter.filter_category} onChange={(evt) => handleChangeFilter(evt)}/>
						</div>
						<div style={{"width":"15%","float":"left","paddingLeft":"12px"}}>
							<Dropdown name="filter_group" id="filter_group" style={{"width":"100%"}} options={groupListFilter} value={inputValuesFilter.filter_group} optionLabel="group_name" optionValue="id" onChange={(evt) => handleChangeFilter(evt)}/>
						</div>
						<div style={{"width":"18%","float":"left","paddingLeft":"18px","color":"#000000"}}>
							<h5 style={{"marginTop":"6px"}}>{inventoryList.length} record(s) found.</h5>
						</div>
						<div style={{"width":"13%","float":"right","paddingRight":"15px"}}>
							<Button label="Add new item" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" style={{"float":"right","marginBottom":"12px"}} onClick={(evt) => {handleButton(evt,"add")}}/>
						</div>
						<ScrollPanel style={{"width":"100%","height":"90%"}}>
							<DataTable
								value={inventoryList}
								scrollable
								scrollHeight="73vh"
								style={{"minHeight":"73vh"}}
								scrollDirection="both"
								stripedRows
								resizableColumns
								columnResizeMode="fit"
								responsiveLayout="stack"
							>
								<Column field="sku" header="SKU" style={{"flexGrow":"1","flexBasis":"120px"}} sortable></Column>
								<Column field="description" header="Description" style={{"flexGrow":"1","flexBasis":"450px"}} sortable></Column>
								<Column field="category" header="Category - Group" style={{"flexGrow":"1","flexBasis":"90px"}} body={tableColTemplate} sortable></Column>
								<Column field="quantity" header="QTY." style={{"flexGrow":"1","flexBasis":"90px"}} body={tableColTemplate}></Column>
								<Column field="buttons" header="" style={{"flexGrow":"0","flexBasis":"180px"}} body={tableColTemplate}></Column>
							</DataTable>
						</ScrollPanel>
					</div>
				</Box>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openListUnits && listUnitsDialog()}
				{dialogValues.openListGroups && listGroupsDialog()}
				{dialogValues.openAddEditUnits && addEditUnitsDialog()}
				{dialogValues.openAddEditGroups && addEditGroupsDialog()}
				{dialogValues.openAddEditQuantities && addEditQuantitiesDialog()}
				{dialogValues.openStockInformations && stockInformationsDialog()}
            </BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default Inventory;